import React, { useEffect, useState } from 'react'
import { OPEN_CATS_URL } from '../../config';
import $ from 'jquery'
import { crypt } from '../Common/crypto';

export default function CareerReports(props) {
    const [careersData, setCareersData] = useState([])
    const [loader, setLoader] = useState(false)
    const [listView, setListView] = useState()

    useEffect(() => {
        if (listView) {
            getAllList()
        }
    }, [listView])

    useEffect(() => {
        if (careersData?.length > 0) {
            $(document).ready(function () {
                $('#careersTable tfoot th').each(function () {
                    var title = $(this).text();
                    $(this).html('<input type="text" placeholder="Search ' + title + '" />');
                });
                var table = window.$('#careersTable').DataTable({
                    "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                    "order": [[0, "desc"]],
                    dom: 'Bfrtip',
                    mark: true,
                    "bDestroy": true,
                    buttons: [
                        'pageLength',
                        'selectAll',
                        'selectNone',
                        {
                            extend: 'excelHtml5',
                            autoFilter: true,
                            sheetName: 'Exported data',
                            text: 'Export'

                        }],
                    select: true
                })
                table.columns().every(function () {
                    var that = this;

                    $('input', this.footer()).addClass('input-class-footer').on('keyup change', function () {
                        if (that.search() !== this.value) {
                            that
                                .search(this.value)
                                .draw();
                        }

                    });
                });
                $('input.toggle-vis').on('change', function (e) {
                    e.preventDefault()
                    var columnIndex = parseInt($(this).attr('data-column'));
                    table.column(columnIndex).visible(!this.checked);
                });
            })
        }
    }, [careersData, new URLSearchParams(window.location.search).get('page')])

    const getAllList = async () => {
        setLoader(true)
        setCareersData([])
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getJobs`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setCareersData(result)

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const handleView = (type) => {
        if (type === 'view') {
            setListView(true)
        }
    }

    useEffect(() => {
        if (new URLSearchParams(window.location.search).get('type') === 'view') {
            handleView('view')
        }
    }, [new URLSearchParams(window.location.search).get('type')])

    return (
        props?.showCareers && (
            <div className="ml-4">
                <div style={{ textAlign: 'center' }}>
                    {
                        loader &&
                        <label style={{ color: 'black', fontSize: '18px', fontWeight: '500' }}>Loading...</label>
                    }
                </div>
                <div>
                    <div style={{ width: '95%', margin: 'auto', marginBottom: '10px', fontSize: '13px' }}>
                        {
                            listView && careersData?.length > 0 &&
                            <div className="justify-content-center" style={{ overflow: 'auto' }}>
                                <div className="col-auto">
                                    <label>Hide/Unhide Columns</label>
                                    <div style={{ fontSize: '10px' }}>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="1" /> </label><label className='m-1'>Job order Id</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="2" /> </label><label className='m-1'>Title</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="3" /> </label><label className='m-1'>Duration</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="4" /> </label><label className='m-1'>Location</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="5" /> </label><label className='m-1'>URL</label></label>
                                    </div>
                                    <br />
                                    <table id="careersTable" className='table table-hover table-sm rwd-table' style={{ width: '100%', overflow: 'auto' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ display: 'none' }}>id</th>
                                                <th>Job Id</th>
                                                <th>Title</th>
                                                <th>Duration</th>
                                                <th>Location</th>
                                                <th>URL</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th style={{ display: 'none' }}>id</th>
                                                <th>Job Id</th>
                                                <th>Title</th>
                                                <th>Duration</th>
                                                <th>Location</th>
                                                <th>URL</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            {
                                                careersData?.map((x, index) => {
                                                    return <tr key={x?.joborder_id}>
                                                        <th style={{ display: 'none' }}>{x?.joborder_id}</th>
                                                        <td data-th="Job Id">NOVIJP00{x?.joborder_id}</td>
                                                        <td data-th="Title">{x?.title}</td>
                                                        <td data-th="Duration">{x?.duration}</td>
                                                        <td data-th="Location">{x?.city} {x?.state}</td>
                                                        <td data-th="URL">{<a target={"_blank"} href={`https://rcats.novisync.com/job-view?job-slug=${crypt(`${x.joborder_id}`)}&job=NOVIJP00${x?.joborder_id}`} rel="noreferrer">{`https://rcats.novisync.com/job-view?job-slug=${crypt(`${x.joborder_id}`)}&job=NOVIJP00${x?.joborder_id}`}</a>}</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div >
        )
    )
}