
import React from 'react'
import './App.css';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import FileUpload from './Components/FileUpload';
import Login from './Components/Login';
import Home from './Components/Home';
import Careers from './Components/Careers';
import JobView from './Components/Careers/jobView';
import SingleJobOrder from './Components/JobOrder/singleJobOrder';
import moment from 'moment-timezone';
import ClientSubmitView from './Components/Careers/client-submit-view';
import VendorView from './Components/Vendor/vendor-view';
import Dashboard from './Components/Dashboard';

function App() {

  moment.tz.setDefault("America/New_York");

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/fileUpload" component={FileUpload} exact />
        <Route path="/home" component={Home} exact />
        <Route path="/careers" component={Careers} exact />
        <Route path="/Careers" component={Careers} exact />
        <Route path="/job-view" component={JobView} exact />
        <Route path="/client-submit-view" component={ClientSubmitView} exact />
        <Route path="/singleJobOrder" component={SingleJobOrder} exact />
        <Route path="/vendor-view" component={VendorView} exact />

      </Switch>
    </BrowserRouter>
  );
}

export default App;
