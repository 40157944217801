import React, { useEffect, useState } from 'react'
//eslint-disable-next-line
import { Link, useHistory } from 'react-router-dom'
import { BUILD_DATE, OPEN_CATS_URL, TASK_URL, VERSION } from '../../../config'
import { removeToken, getToken } from '../../../storage'
import { setToken } from '../LocalStorage'
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Navbar(props) {

    const history = useHistory()
    const [data, setData] = useState({})
    const [supportView, setSupportView] = useState(false)
    const [supportData, setSupportData] = useState()

    useEffect(() => {
        if (window.location.pathname !== "/careers" && window.location.pathname !== "/Careers" && window.location.pathname !== "/job-view" && window.location.pathname !== "/client-submit-view") {
            getUserInfo()
        }
        // eslint-disable-next-line
    }, [])


    const getFieldsJson = async () => {
        await fetch(
            `${OPEN_CATS_URL}/authRouter/getFields}`)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        // getFieldsJson()

        var properties = {
            NEW_ACTIVITY: "New Activity",
            EXISTING_ACTIVITY: "Existing Activity",
            CANDIDATES: "Candidates",
            JOB_ORDERS: "Job order",
            JOB_ORDER_PIPELINE: "Joborder Pipeline",
            REPORTS: "Reports",
            SETTINGS: "Settings"
        }

        setToken('properties', JSON.stringify(properties))

    }, [])

    useEffect(() => {
        if (getToken('id') === null || undefined) {
            handleLogout()
        }
        // eslint-disable-next-line
    }, [])

    const handleLogout = () => {
        removeToken('login')
        removeToken('properties')
        removeToken('id')
        removeToken('candidateData')
        removeToken('joborderData')
        removeToken('user')
        if (window.location.pathname !== "/careers" && window.location.pathname !== "/Careers" && window.location.pathname !== "/job-view" && window.location.pathname !== "/client-submit-view" && window.location.pathname !== "/vendor-view") {
            history.push('/')
        }
    }

    const getUserInfo = async () => {
        await fetch(
            `${OPEN_CATS_URL}/authRouter/getUserInfo?id=${getToken('id')}`)
            .then((response) => response.json())
            .then((result) => {
                setData(result.data)
                setToken('user', result.data.user_name)
                props.getAccess(result.data)
            })
            .catch((error) => {
                handleLogout()
                console.error('Error:', error);
            });
    }

    const handleChange = (e) => {
        setSupportData({
            ...supportData,
            [e.target.name]: e.target.value
        })
    }

    const createSupportTicket = async (e) => {
        e.preventDefault()
        fetch(
            `${TASK_URL}/manage_userstories.php`,
            {
                method: 'POST',
                body: JSON.stringify({
                    action: "addMainTaskFromOthers",
                    title: supportData.title,
                    description: supportData.description,
                    projectId: '50b596a0b0cfdc89af2b1f85728d659d'
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        ).then((response) => response.json())
            .then((result) => {
                toast.success(result.message)
                setSupportView(false)
            })
            .catch((error) => {
                console.error('Error:', error);
                setSupportView(false)
            });
    }

    return (
        <nav className="navbar navbar-expand-md navbar-dark fixed-top my-nav-main  ">
            <ToastContainer />
            <div className="container-fluid">
                <img className="navbar-brand" alt="Novisync NATS" src="./images/main-logo.png" style={{ width: '30%' }} />
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    {
                        (window.location.pathname !== "/careers" && window.location.pathname !== "/Careers" && window.location.pathname !== '/job-view' && window.location.pathname !== '/client-submit-view' && window.location.pathname !== "/vendor-view") &&
                        (
                            <div className="navbar-nav ml-auto" style={{ fontSize: '12px' }}>

                                <li className="nav-item nav-link active">v {VERSION}{" "}{BUILD_DATE}</li>
                                <li style={{ cursor: 'pointer' }} className="nav-item nav-link active" onClick={() => setSupportView(true)}><i class="bi bi-headset"></i> Support</li>
                                <li style={{ cursor: 'pointer' }} className="nav-item nav-link active"><i class="bi bi-person"></i> {data.user_name}</li>
                                <li style={{ cursor: 'pointer' }} className="nav-item nav-link active" onClick={() => handleLogout()} ><i class="bi bi-box-arrow-right"></i> Logout</li>
                            </div>
                        )
                    }
                </div>
                <Modal
                    isOpen={supportView}
                    onRequestClose={() => {
                        setSupportView(false)
                    }}
                    style={customStyles}
                    shouldCloseOnOverlayClick={false}
                >
                    <div className='container'>
                        <center className='mt-2 mb-3'>
                            <h5>HOW CAN WE HELP YOU?</h5>
                        </center>
                        <form onSubmit={(e) => createSupportTicket(e)}>
                            <div className='container'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <h5>Tell us about the issue you are facing!</h5>
                                    </div>
                                    <div className='card-body'>
                                        <div className='form-group'>
                                            <label>Could You Please Describe This Issue In One Sentence?</label>
                                            <textarea name="title" col={4} className='form-control' onChange={(e) => handleChange(e)} required />
                                        </div>
                                        <div className='form-group'>
                                            <label>Let Us Know How To Reproduce The Issue For Us To Work On?</label>
                                            <textarea name='description' col={4} className='form-control' onChange={(e) => handleChange(e)} required />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <center className='mt-2'>
                                <button className="btn btn-outline-dark btn-sm mr-2" onClick={() => {
                                    console.log('save')
                                }}>Save</button>
                                <button className="btn btn-outline-danger btn-sm" onClick={() => {
                                    setSupportView(false)
                                }}>close</button>
                                <br />
                            </center>
                        </form>
                    </div>
                </Modal>
            </div>
        </nav >
    )
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        height: '85%',
        fontSize: '11px'
    },
    overlay: { zIndex: 9999999999 }
};