import React, { useEffect, useState } from 'react'
import { OPEN_CATS_URL, PARSING_URL } from '../../config';
import { getToken, removeToken, setToken } from '../../storage';
import Select, { createFilter } from 'react-select';
import SingleFileUpload from '../FileUpload/singleFileUpload'
import Modal from 'react-modal';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { FixedSizeList as List } from "react-window";
import AsyncSelect from 'react-select/async';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import $ from 'jquery'
import { crypt } from '../Common/crypto';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { sendErrorEmailAlert } from '../Common/Alerts';

export default function ClientSubmit(props) {

    const format = 'h:mm a';
    const now = moment().hour(0).minute(0);

    const [jobOrder, setJobOrder] = useState([])
    const [jobOrderId, setJobOrderId] = useState(null)
    const [candidates, setCandidates] = useState([])
    const [createCandidate, setCreateCandidate] = useState(false)
    const [selectedCandidate, setSelectedCandidate] = useState(null)
    const [insertId, setInsertId] = useState()
    //eslint-disable-next-line
    const [isInvalidResume, setIsInvalidResume] = useState(false)
    const [loader, setLoader] = useState(false)
    const [message, setMessage] = useState()
    const [users, setUsers] = useState([])
    const [editorState, setEditorState] = useState()
    const [company, setCompany] = useState([])
    const [description, setDescription] = useState()
    const [location, setLocation] = useState()
    const [workAuthorization, setWorkAuthorization] = useState()
    const [payRate, setPayRate] = useState()
    const [duration, setDuration] = useState()
    const [data, setData] = useState({
        jobTitle: null,
        company: null,
        startDate: null,
        duration: null,
        jobType: null,
        openings: null,
        billRate: null,
        payRate: null,
        city: null,
        state: null,
        work_authorization: null,
        recruiter: null,
        owner: null,
        keySkills: null,
        experience_needed: null,
        submissionDeadline: null,
        priority: null,
        bdmComments: null,
        country: null,
        internalNotes: null,
        jobContact: -1,
        jobDepartment: null,
        client_job_id: null
    })

    const [jobNotes, setJobNotes] = useState(null)
    const [candidateView, setCandidateView] = useState(false)
    const [loadOptionsData, setLoadOptionsData] = useState([])
    const [contactDetails, setContactDetails] = useState()
    const [resumeFile, setResumeFile] = useState(null)
    const [coverLetterFile, setCoverLetterFile] = useState(null)
    const [submissionDetails, setSubmissionDetails] = useState(null)
    const [coverLetter, setCoverLetter] = useState(null)
    const [resumeData, setResumeData] = useState([])
    const [viewType, setViewType] = useState('none')
    const [submitView, setSubmitView] = useState(false)
    const [viewSubmissionsData, setViewSubmissionData] = useState(false)
    const [previewModal, setPreviewModal] = useState(false)
    const [submittedCandidateData, setSubmittedCandidateData] = useState([])
    const [selectedJobOrderId, setSelectedJobOrderId] = useState(0)
    const [resultData, setResultData] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [selectedSubmissionId, setSelectedSubmissionId] = useState(0)
    const [updatePreviewData, setUpdatePreviewData] = useState()
    const [previewStatus, setPreviewStatus] = useState('preview')
    const [editorSubmissionDetails, setEditorSubmissionDetails] = useState()
    const [editorCoverLetter, setEditorCoverLetter] = useState()
    const [interviewModal, setInterviewModal] = useState(false)
    const [selectedTime, setSelectedTime] = useState({
        timeSlot1: null,
        timeSlot2: null,
        timeSlot3: null,
    });
    const [interviewNotes, setInterviewNotes] = useState(null)
    const [interviewData, setInterviewData] = useState(null)


    useEffect(() => {
        setCandidateView(false)
    }, [])


    useEffect(() => {
        if (props.showClientSubmit === false) {
            setJobOrderId(null)
            setCandidates([])
            setCreateCandidate(false)
            setSelectedCandidate(null)
            setInsertId()
            setIsInvalidResume(false)
            setLoader(false)
            setMessage()
        }
    }, [props])



    useEffect(() => {
        if (jobOrderId !== null) {
            getCandidates()
        }
    }, [jobOrderId])

    const getJobOrder = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getAllJobOrder?id=${getToken('id')}`)
            .then((response) => response.json())
            .then((result) => {
                setJobOrder(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const getCandidates = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getCandidates?id=${getToken('id')}`)
            .then((response) => response.json())
            .then((result) => {
                setCandidates(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    var jobOrderData = jobOrder.map(x => {
        return {
            value: x.joborder_id, label: `NOVIJP00${x.joborder_id}-${x.title}`, data: x
        }
    })

    const handleSelect = (selectedOption, type) => {
        if (type === 'joborder') {
            setJobOrderId(selectedOption)
        }
        if (type === "candidate") {
            setSelectedCandidate(selectedOption)
        }
    }



    var candidatesData = candidates.map(x => {
        return {
            value: x.candidate_id, label: `${x.first_name + x.last_name}`, data: x
        }
    })
    var newCandidatesData = candidatesData
    var newJobOrderData = jobOrderData

    function reverseArr(input) {
        //eslint-disable-next-line
        var ret = new Array;
        for (var i = input.length - 1; i >= 0; i--) {
            ret.push(input[i]);
        }
        return ret;
    }

    const handleSubmitClientSubmission = async (type) => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/UpdateClientSubmission`,
            {
                method: 'POST',
                body: JSON.stringify({
                    submission_history_id: resultData?.insertId,
                    addedBy: getToken('id'),
                    candidate_id: selectedCandidate.value,
                    jobOrder_id: jobOrderId.value,
                    resume_file: resumeFile[0].resume_link,
                    submission_details: submissionDetails,
                    cover_letter: coverLetter,
                    contact_email: jobOrderId.data.contact_email,
                    contact_first_name: jobOrderId.data.contact_first_name,
                    contact_last_name: jobOrderId.data.contact_last_name,
                    candidate_name: `${selectedCandidate.data.first_name} ${selectedCandidate.data.last_name}`,
                    job_title: jobOrderId.data.title,
                    cover_letter_file: coverLetter === null ? coverLetterFile[0]?.resume_link : null,
                    status: type === "submit" ? "submitted" : "preview"
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                if (type === "submit") {
                    toast.success('Candidate Submitted to Client')
                    handleView('view')
                    setPreviewModal(false)
                    setIsEdit(false)
                    setJobOrderId(null)
                    setSelectedCandidate(null)
                    setLoader(false)
                } else {
                    setPreviewStatus('preview')
                    setPreviewModal(true)
                    setSelectedSubmissionId(resultData?.insertId)
                    setSelectedJobOrderId(jobOrderId.value)
                    setViewType('add')
                    setLoader(false)
                }

            })
            .catch((error) => {
                toast.error('Failed Candidate Client Submit')
                setLoader(false)
            });
        setLoader(false)
    }

    const handleSubmitClient = async (status) => {
        setLoader(true)
        if (resumeFile !== null && (coverLetterFile !== null || coverLetter !== null) && submissionDetails !== null) {
            if (jobOrderId?.data?.contact_email !== undefined) {
                await fetch(
                    `${OPEN_CATS_URL}/careersRouter/clientSubmit`,
                    {
                        method: 'POST',
                        body: JSON.stringify({
                            addedBy: getToken('id'),
                            candidate_id: selectedCandidate.value,
                            jobOrder_id: jobOrderId.value,
                            resume_file: resumeFile[0]?.resume_link,
                            submission_details: submissionDetails,
                            cover_letter: coverLetter,
                            contact_email: jobOrderId.data.contact_email,
                            contact_first_name: jobOrderId.data.contact_first_name,
                            contact_last_name: jobOrderId.data.contact_last_name,
                            candidate_name: `${selectedCandidate.data.first_name} ${selectedCandidate.data.last_name}`,
                            job_title: jobOrderId.data.title,
                            cover_letter_file: coverLetter === null ? coverLetterFile[0]?.resume_link : null,
                            status: status
                        }),
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                )
                    .then((response) => response.json())
                    .then((result) => {
                        setResultData(result.data)
                        setPreviewStatus('preview')
                        setPreviewModal(true)
                        setSelectedJobOrderId(jobOrderId.value)
                        setSelectedSubmissionId(result.data.insertId)
                        setViewType('add')

                    })
                    .catch((error) => {
                        toast.error('Failed Candidate Client Submit')
                        setLoader(false)
                    });
            } else {
                toast.error('Contact Email Not Available')
                setLoader(false)
            }
        } else {
            toast.error('Please Fill All Required Fields')

        }
        setLoader(false)
    }


    useEffect(() => {
        if (jobOrderId !== null) {
            setWorkAuthorization()
            setPayRate()
            var description = jobOrder.filter(y => { return y.joborder_id === jobOrderId.value })
            setDescription(description[0]?.description)
            setLocation(description[0]?.city)
            setDuration(description[0]?.duration)
            setJobNotes(description[0]?.notes)
            setContactDetails(description[0])
            fetch(
                `${OPEN_CATS_URL}/eventRouter/getWorkAuthorization?job_id=${jobOrderId.value}`)
                .then((response) => response.json())
                .then((result) => {
                    setWorkAuthorization(result.data[0]?.value)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            fetch(
                `${OPEN_CATS_URL}/eventRouter/getPayRate`,
                {
                    method: 'POST',
                    body: JSON.stringify({ joborderId: jobOrderId.value }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    setPayRate(result.data[0]?.value)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
        //eslint-disable-next-line
    }, [jobOrderId])

    const MenuList = function MenuList(props) {
        const { options, children, maxHeight, getValue } = props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * 35;

        return (
            <List
                height={maxHeight}
                itemCount={children.length}
                itemSize={35}
                initialScrollOffset={initialOffset}
            >
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </List>
        );
    };


    const loadOptions = (inputValue) => {
        if (inputValue.length >= 3) {
            return fetch(`${OPEN_CATS_URL}/eventRouter/getCandidates?id=${getToken('id')}&access=${props?.access?.access_level}&value=${inputValue}`).then((response) => response.json())
                .then((result) => {
                    var d = result.data.map(x => {
                        return {
                            value: x.candidate_id, label: `${x.first_name + x.last_name} - ${x.email1}`, data: x
                        }
                    })
                    setLoadOptionsData(d)
                    return d
                })
        }
    };

    const handleOptionChange = value => {
        setResumeData([])
        handleSelect(value, 'candidate')

    }


    const handleFileUpload = async (e, type) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
            formData.append(`file`, e.target.files[i])
        }
        await fetch(
            `${PARSING_URL}/mass_upload`,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                removeToken('emailType')
                if (result.parsed_files.length > 0) {
                    if (type === 'resume') {
                        setResumeFile(result.parsed_files)
                    }
                    if (type === 'cover_letter') {
                        setCoverLetterFile(result.parsed_files)
                    }
                    setLoader(false)
                } else {
                    setLoader(false)
                    console.log("Resume Not Parsed, Please Upload Different Format")
                }

            })
            .catch((error) => {
                sendErrorEmailAlert('parsing', getToken('user'), error)
                console.error('Error:', error);
                setLoader(false)
            });
        setLoader(false)
    };

    const handleView = (type) => {
        if (type === "submit") {
            setSubmitView(true)
            setViewSubmissionData(false)
            getJobOrder()
        }
        if (type === "view") {
            setSubmitView(false)
            setViewSubmissionData(true)
            getSubmittedCandidates()
        }
    }

    const getSubmittedCandidates = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getSubmittedCandidates`)
            .then((response) => response.json())
            .then((result) => {
                setSubmittedCandidateData(result)
            })
            .catch((error) => {
                setLoader(false)
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        if (new URLSearchParams(window.location.search).get('type') === 'submit' && new URLSearchParams(window.location.search).get('page') === 'client-submit') {
            handleView('submit')
        }
        if (new URLSearchParams(window.location.search).get('type') === 'view' && new URLSearchParams(window.location.search).get('page') === 'client-submit') {
            handleView('view')
        }
    }, [new URLSearchParams(window.location.search).get('type')])

    useEffect(() => {
        if (submittedCandidateData.length > 0) {
            $(document).ready(function () {
                $('#submittedCandidateData tfoot th').each(function () {
                    var title = $(this).text();
                    $(this).html('<input type="text" placeholder="Search ' + title + '" />');
                });
                var table = window.$('#submittedCandidateData').DataTable({
                    "order": [[0, "desc"]],
                    mark: true,
                    "bDestroy": true,
                })
                table.columns().every(function () {
                    var that = this;

                    $('input', this.footer()).addClass('input-class-footer').on('keyup change', function () {
                        if (that.search() !== this.value) {
                            that
                                .search(this.value)
                                .draw();
                        }

                    });
                });
                $('input.toggle-vis').on('change', function (e) {
                    e.preventDefault()
                    var columnIndex = parseInt($(this).attr('data-column'));
                    table.column(columnIndex).visible(!this.checked);
                });
            })
        }
    }, [submittedCandidateData, new URLSearchParams(window.location.search).get('page')])

    useEffect(() => {
        if (viewType === 'updatePreview') {
            let job_data = {
                value: selectedJobOrderId, label: `NOVIJP00${selectedJobOrderId}-${updatePreviewData.title}`, data: updatePreviewData
            }
            let candidate_data = {
                value: updatePreviewData.candidate_id, label: `${updatePreviewData.first_name + updatePreviewData.last_name} - ${updatePreviewData.email1}`, data: updatePreviewData
            }
            handleSelect(job_data, 'joborder')
            handleSelect(candidate_data, 'candidate')
            setSubmissionDetails(updatePreviewData.submission_data)
            setCoverLetter(updatePreviewData.cover_letter_data)
            setResumeFile([{ resume_link: updatePreviewData.resume_path }])
            setCoverLetterFile([{ resume_link: updatePreviewData.cover_letter_path }])
            setResultData({ insertId: updatePreviewData.id })
            setIsEdit(true)
        }
    }, [viewType])

    const onEditorStateChange = (editorState, type) => {
        var rawContentState = convertToRaw(editorState.getCurrentContent());
        var markup = draftToHtml(
            rawContentState,
        );
        if (type === 'submissionDetails') {
            setSubmissionDetails(markup)
            setEditorSubmissionDetails(editorState)
        }
        if (type === 'coverLetter') {
            setCoverLetter(markup)
            setEditorCoverLetter(editorState)
        }
    }
    function onChange(value, type) {
        console.log(value.format('HH:mm A'), type);
        setSelectedTime({
            ...selectedTime,
            [type]: value.format("hh:mm A")
        })
    }

    const handleSubmitInterview = () => {
        if (selectedTime.timeSlot1 !== null && interviewNotes !== null) {
            fetch(
                `${OPEN_CATS_URL}/careersRouter/updateClientInterviewSchedule`,
                {
                    method: 'POST',
                    body: JSON.stringify({ interviewData: interviewData, selectedTime: selectedTime, interviewNotes: interviewNotes }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    toast.success(result.message)
                    setInterviewModal(false)
                    setInterviewData(null)
                    getSubmittedCandidates()
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        } else {
            toast.warning("Please Fill Required Fields")
        }
    }


    return (
        <div className="container">
            <ToastContainer />
            {
                props.showClientSubmit && submitView && (
                    <div style={{ fontSize: '13px' }}>
                        <label style={{ color: 'green', fontSize: '18px', fontWeight: '500' }}>{message}</label>
                        {
                            jobOrder.length === 0 &&
                            <label style={{ color: 'black', fontSize: '18px', fontWeight: '500' }}>Loading...</label>

                        }
                        {
                            jobOrder.length > 0 && (
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Select Job Order*</label>
                                    <Select
                                        filterOption={createFilter({ ignoreAccents: false })}
                                        styles={customStyles2}
                                        // value={selectedCandidate}
                                        components={{ MenuList }}
                                        placeholder={jobOrderId !== null ? jobOrderId?.label : "Select Job Order"}
                                        onChange={(selectedOption) => handleSelect(selectedOption, 'joborder')}
                                        options={reverseArr(newJobOrderData)} />
                                </div>
                            )
                        }


                        {jobOrderId !== null && (
                            <div className="form-group">
                                <label style={{ fontWeight: 'bold' }}>Select Candidate*</label>
                                <sub>{" "}(Enter more then 3 characters to search)</sub>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions={loadOptionsData}
                                    loadOptions={loadOptions}
                                    onChange={handleOptionChange}
                                    placeholder={selectedCandidate !== null ? selectedCandidate?.label : "Select Candidate"}
                                />
                            </div>
                        )}
                        {
                            selectedCandidate !== null &&
                            <>
                                <div className='mt-1 mb-2'>
                                    <label>Novisync Formatted Resume*</label>
                                    <br />
                                    <input type="file" name="file" onChange={(e) => handleFileUpload(e, 'resume')} />
                                </div>

                                <div className='mt-1 mb-1'>
                                    <label>Cover Letter*</label>
                                    <br />
                                    <input type="file" name="file" onChange={(e) => handleFileUpload(e, 'cover_letter')} />
                                    <br />
                                    OR
                                    <br />
                                    <Editor
                                        editorStyle={{ borderStyle: 'solid', minHeight: '100px' }}
                                        editorState={editorCoverLetter}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={(editorState) => onEditorStateChange(editorState, 'coverLetter')}
                                    />
                                </div>
                                <div className='mt-1 mb-1'>
                                    <label>Submission Details*</label>
                                    <Editor
                                        editorStyle={{ borderStyle: 'solid', minHeight: '100px' }}
                                        editorState={editorSubmissionDetails}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={(editorState) => onEditorStateChange(editorState, 'submissionDetails')}
                                    />
                                </div>
                            </>
                        }
                        {
                            jobOrder.length > 0 &&
                            <>
                                {isEdit ?
                                    <button type="button" disabled={jobOrderId === null || resumeFile === null || loader} onClick={() => handleSubmitClientSubmission('preview')} className="btn btn-outline-dark btn-sm mt-2 mr-2">{loader ? 'Loading...' : 'Save & Preview'}</button>
                                    :
                                    <button type="button" disabled={jobOrderId === null || resumeFile === null || loader} onClick={() => handleSubmitClient('preview')} className="btn btn-outline-dark btn-sm mt-2 mr-2">{loader ? 'Loading...' : 'Save & Preview'}</button>
                                }
                            </>
                        }
                        <p className='mt-2'>
                            {message}
                        </p>
                    </div >
                )
            }
            {
                props.showClientSubmit && viewSubmissionsData && (
                    <div>
                        <div>
                            {
                                submittedCandidateData?.length > 0 ?
                                    <div className="row justify-content-center" style={{ overflow: 'auto' }}>
                                        <div className="col-auto">
                                            <label>Hide/Unhide Columns</label>
                                            <div style={{ fontSize: '10px' }}>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="1" /> </label><label className='m-1'>Candidate Name</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="2" /> </label><label className='m-1'>Joborder Id</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="3" /> </label><label className='m-1'>Joborder</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="4" /> </label><label className='m-1'>Company</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="5" /> </label><label className='m-1'>Submission Date</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="6" /> </label><label className='m-1'>Submitted By</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="7" /> </label><label className='m-1'>Status</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="8" /> </label><label className='m-1'>Action</label></label>
                                                <label><label><input type="checkbox" className="toggle-vis" data-column="9" /> </label><label className='m-1'>Preview</label></label>

                                            </div>
                                            <br />
                                            <table id="submittedCandidateData" className='table table-hover table-sm rwd-table' style={{ width: '100%', overflow: 'auto' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ display: 'none' }}>Id</th>
                                                        <th>Candidate Name</th>
                                                        <th>Joborder ID</th>
                                                        <th>Joborder</th>
                                                        <th>Company</th>
                                                        <th>Submission Date</th>
                                                        <th>Submitted By</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                        <th>Preview</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th style={{ display: 'none' }}>Id</th>
                                                        <th>Candidate Name</th>
                                                        <th>Joborder ID</th>
                                                        <th>Joborder</th>
                                                        <th>Company</th>
                                                        <th>Submission Date</th>
                                                        <th>Submitted By</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                        <th>Preview</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    {
                                                        submittedCandidateData?.map(x => {
                                                            return (
                                                                <tr>
                                                                    <td style={{ display: 'none' }}>{x.id}</td>
                                                                    <td data-th="Candidate Name">{x.first_name} {x.last_name}</td>
                                                                    <td data-th="Joborder ID">{`NOVIJP00${x.joborder_id}`}</td>
                                                                    <td data-th="Joborder">{x.title}</td>
                                                                    <td data-th="Company">{x.company_name}</td>
                                                                    <td data-th="Submission Date">{moment(x.created_date).format('lll')}</td>
                                                                    <td data-th="Submitted By">{x.user_first_name} {x.user_last_name}</td>
                                                                    <td data-th="Status" style={{ textTransform: 'capitalize' }}>{x.status}</td>
                                                                    <td data-th="Action">
                                                                        {

                                                                            x.status === 'shortlisted' ?
                                                                                x.time_slot1 === null ?
                                                                                    <>
                                                                                        <button className='btn btn-outline-dark btn-sm' type="button" onClick={() => {
                                                                                            setInterviewData(x)
                                                                                            setInterviewModal(true)
                                                                                        }}>Schedule Interview</button>
                                                                                    </> : "Interview Scheduled"
                                                                                :
                                                                                ""
                                                                        }
                                                                    </td>
                                                                    <td data-th="Preview">
                                                                        {
                                                                            x.status === 'preview' ?
                                                                                <button className="btn btn-info btn-sm" onClick={() => {
                                                                                    handleView('submit')
                                                                                    setViewType('updatePreview')
                                                                                    setUpdatePreviewData(x)
                                                                                    setSelectedJobOrderId(x.joborder_id)
                                                                                    setSelectedSubmissionId(x.id)
                                                                                }
                                                                                }>Edit</button>
                                                                                :
                                                                                <button className="btn btn-info btn-sm" onClick={() => {
                                                                                    setPreviewModal(true)
                                                                                    setViewType('none')
                                                                                    setSelectedJobOrderId(x.joborder_id)
                                                                                    setSelectedSubmissionId(x.id)
                                                                                    setPreviewStatus(x.status)
                                                                                }
                                                                                }>View</button>
                                                                        }

                                                                    </td>

                                                                </tr>
                                                            )
                                                        })
                                                    }


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        {
                                            submittedCandidateData.length === 0 ?
                                                <center>No Data Found</center>
                                                :
                                                <center>Loading...</center>
                                        }
                                    </div>
                            }

                        </div>
                    </div >
                )
            }

            <div>
                <Modal
                    isOpen={previewModal}
                    onRequestClose={() => {
                        setPreviewModal(false)
                    }}
                    style={customStyles}

                    shouldCloseOnOverlayClick={false}
                >
                    <div>
                        {/* <div className='mt-1'>
                            <button style={{ float: 'right', marginBottom: '5px' }} className="btn btn-danger btn-sm" onClick={() => {
                                setPreviewModal(false)
                                setSelectedSubmissionId(0)
                                setSelectedJobOrderId(0)
                            }}>close</button>
                        </div> */}
                        <iframe frameborder="0" id="gview" key={previewModal} title='view' src={`/client-submit-view?job-slug=${crypt(selectedJobOrderId.toString())}&job=NOVIJP00${selectedJobOrderId}&submit-slug=${crypt(selectedSubmissionId.toString())}&preview=false&status=${previewStatus}`} style={{ height: '59vh', width: '100%' }}
                            sandbox="allow-scripts allow-same-origin"
                        ></iframe>
                        <center>
                            {
                                viewType === 'add' ?
                                    <>
                                        <button className="btn btn-success btn-sm mr-2" onClick={() => handleSubmitClientSubmission("submit")}>Submit</button>
                                        <button className="btn btn-info btn-sm mr-2" onClick={() => {
                                            setIsEdit(true)
                                            setPreviewModal(false)
                                        }}>Edit</button>
                                        <button className="btn btn-danger btn-sm mr-2" onClick={() => {
                                            setPreviewModal(false)
                                        }}>close</button>
                                    </>
                                    :
                                    <button className="btn btn-danger btn-sm" onClick={() => {
                                        setPreviewModal(false)
                                        setSelectedSubmissionId(0)
                                        setSelectedJobOrderId(0)
                                    }}>close</button>
                            }
                            <br />
                        </center>
                    </div>

                </Modal>
            </div>
            <div>
                <Modal
                    isOpen={interviewModal}
                    onRequestClose={() => {
                        setInterviewModal(false)
                    }}
                    style={customStyles}
                    shouldCloseOnOverlayClick={false}
                >
                    <div className='container'>
                        <center className='mt-2 mb-3'>
                            <h5>Select Time Slots For Interview</h5>
                        </center>
                        <div className='mt-2 container'>
                            <form>
                                <div className='row col-12'>
                                    <div className='col-2' />
                                    <div className='col-3'>
                                        <label style={{ textAlign: 'left' }}>
                                            Time Slot 1*
                                        </label>
                                        <TimePicker
                                            showSecond={false}
                                            // defaultValue={now}
                                            className="xxx"
                                            onChange={(value) => onChange(value, 'timeSlot1')}
                                            format={format}

                                            inputReadOnly
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <label style={{ textAlign: 'left' }}>
                                            Time Slot 2
                                        </label>
                                        <TimePicker
                                            showSecond={false}
                                            // defaultValue={now}
                                            className="xxx"
                                            onChange={(value) => onChange(value, 'timeSlot2')}
                                            format={format}

                                            inputReadOnly
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <label style={{ textAlign: 'left' }}>
                                            Time Slot 3
                                        </label>
                                        <TimePicker
                                            showSecond={false}
                                            // defaultValue={now}
                                            className="xxx"
                                            onChange={(value) => onChange(value, 'timeSlot3')}
                                            format={format}

                                            inputReadOnly
                                        />
                                    </div>
                                    <div className='col-1' />
                                </div>
                                <div className='mt-2 col-12'>
                                    <label>Interview Notes*</label>
                                    <textarea col={4} className='form-control' placeholder='Please Enter Inteview Notes' onChange={(e) => setInterviewNotes(e.target.value)} />
                                </div>
                            </form>
                        </div>

                        <br />
                        <center>
                            <button className="btn btn-outline-dark btn-sm mr-2" onClick={() => {
                                handleSubmitInterview()
                            }}>Save</button>
                            <button className="btn btn-outline-danger btn-sm" onClick={() => {
                                setInterviewModal(false)
                            }}>close</button>
                            <br />
                        </center>
                    </div>

                </Modal>
            </div >

        </div >

    )
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '85%',
        height: '70%',
        padding: '5px'
    },
};

const customStyles2 = {
    menu: (provided, state) => ({
        ...provided,
        minHeight: '300px',
        height: '300px',
    })
};