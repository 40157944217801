import { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { OPEN_CATS_URL, PARSING_URL } from '../../config';
import { getToken, removeToken } from '../../storage';
import Modal from 'react-modal';
import { sendErrorEmailAlert } from '../Common/Alerts';

export default function UpdateFileUpload(props) {
    const [selectedFile, setSelectedFile] = useState();
    const [data, setData] = useState([])
    const [view, setView] = useState(false)
    const [loader, setLoader] = useState(false)
    const [message, setMessage] = useState()
    const [resultData, setResultData] = useState()
    const [resultView, setResultView] = useState()
    const [inputkeyValue, setInputKey] = useState(false)


    useEffect(() => {
        setView(false)
    }, [message])

    const changeHandler = (event) => {
        setSelectedFile(event.target.files);
        setView(false)
        setMessage(false)
    };


    const customAlert = () => {
        confirmAlert({
            title: 'Confirm to Cancel',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setView(false)
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log('Click No')
                }
            ]
        });

    }

    const handleSubmission = async (e) => {
        setMessage()
        setLoader(true)
        e.preventDefault()
        const formData = new FormData();
        for (let i = 0; i < selectedFile.length; i++) {
            formData.append(`file`, selectedFile[i])
        }
        await fetch(
            `${PARSING_URL}/mass_upload`,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                removeToken('emailType')

                setData(result.parsed_files.map(x => ({ ...x, 'country': "" })))
                setView(true)
                setLoader(false)
                setInputKey(!inputkeyValue)
            })
            .catch((error) => {
                sendErrorEmailAlert('parsing', getToken('user'), error)
                console.error('Error:', error);
                setLoader(false)
                setInputKey(!inputkeyValue)
            });
        setLoader(false)
        setInputKey(!inputkeyValue)

    };

    const handleImport = async (e) => {
        e.preventDefault()
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/insertionRouter/updateFile?id=${getToken('id')}`,
            {
                method: 'POST',
                body: JSON.stringify({ data: data, candidate_id: props.candidate_id, attachment_id: props.attachment_id }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                removeToken('candidateData')
                setResultData(result.result)
                setResultView(true)
                setMessage(result.message)
                setLoader(false)
                setView(false)
                props.handleUpdatedResume()
            })
            .catch((error) => {
                console.error('Error:', error);
                setMessage(error.message)
                setLoader(false)
            });
        setLoader(false)
    }



    return (
        <div>
            <Modal
                isOpen={resultView}
                onRequestClose={() => {
                    setSelectedFile(undefined)
                    setData([])
                    setResultView(false)
                    setMessage("")
                }}
                style={customStyles}
                shouldCloseOnOverlayClick={true}
            >
                <div>
                    <label>Bulk Upload Status</label>
                    <table class="table table-striped my-table" style={{ fontSize: '12px' }}>
                        <thead>
                            <tr>
                                <th>File Name</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                resultData?.map((x, i) => {
                                    return (
                                        <tr row={i}>
                                            <td>{x.fileName}</td>
                                            <td>{x.result.first_name}</td>
                                            <td>{x.result.last_name}</td>
                                            <td>{x.result.email1}</td>
                                            <td>{x.result.phone_home}</td>
                                            <td style={x.status === 'Success' ? { color: 'green' } : { color: 'red' }}>
                                                {
                                                    x.status === "Success" ?
                                                        <svg className='p-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                                        </svg>
                                                        :
                                                        <svg className='p-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                        </svg>
                                                }
                                                <label className='p-2'>{x.status}</label>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>
                <center>
                    <button type="button" className='btn btn-outline-dark btn-sm' onClick={() => {
                        setSelectedFile(undefined)
                        setData([])
                        setResultView(false)
                        setMessage("")
                    }}>
                        Close
                    </button>
                </center>
            </Modal>
            <LoadingOverlay
                active={loader}
                spinner
                text='Uploading Resumes, Please Wait...'
            >
                <div className="container border mt-5 p-5 mb-3">
                    <form onSubmit={(e) => handleSubmission(e)}>
                        <div className="form-group">
                            <h5 htmlFor="resumeFile">Upload Resume</h5>
                            <br />
                            <input required
                                key={inputkeyValue}
                                type="file" name="file" onChange={(e) => changeHandler(e)} className="form-control-file" id="resumeFile" />
                        </div>
                        <button type="submit" className="btn btn-outline-dark btn-sm active">Upload Resume</button>
                    </form>
                    {message}
                </div>
                {
                    view && (
                        <div className="container border mt-5 p-1 mb-5" style={{ fontSize: '12px' }}>
                            <form onSubmit={(e) => handleImport(e)}>
                                <div className="col-12 row mt-3 mb-3">
                                    <div className="col-12">
                                        <table class="table table-striped my-table">
                                            <thead>
                                                <tr>
                                                    {/* <th>#</th> */}
                                                    <th>File Name</th>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>Email</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data?.map((x, i) => {
                                                        return (
                                                            <tr row={i}>
                                                                {/* <td>{i + 1}</td> */}
                                                                <td>{x.original_file_name}</td>
                                                                <td>{x.name}</td>
                                                                <td>{x.last_name}</td>
                                                                <td>{x.email_id}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row vertical-center">
                                        <button className="btn btn-outline-dark btn-sm mr-2" type="submit">Import</button>
                                        <button className="btn btn-outline-dark btn-sm" type="reset" onClick={() => customAlert()}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    )
                }
            </LoadingOverlay >
        </div >


    );
}

const customStyles = {
    content: {
        position: 'absolute',
        inset: '40px',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(255, 255, 255)',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px',
        width: '95%',
        height: '90vh',
        margin: 'auto'
    }
};