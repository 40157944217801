import { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { OPEN_CATS_URL, PARSING_URL } from '../../config';
import { getToken, removeToken } from '../../storage';
import Select from 'react-select';
import { sendErrorEmailAlert } from '../Common/Alerts';




export default function SingleFileUpload(props) {
    const [selectedFile, setSelectedFile] = useState();
    const [data, setData] = useState()
    const [view, setView] = useState(false)
    const [loader, setLoader] = useState(false)
    const [message, setMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    //eslint-disable-next-line
    const [resultData, setResultData] = useState()
    //eslint-disable-next-line
    const [resultView, setResultView] = useState()
    // const [keySkill, setKeySkill] = useState()
    // const [inputkeyValue, setInputKey] = useState(false)
    const [resetFile, setResetFile] = useState(false)
    const [candidateSource, setCandidateSource] = useState(null)
    const [sourceInfo, setSourceInfo] = useState([])
    const [othersOpen, setOthersOpen] = useState(false)
    const [othersValue, setOthersValue] = useState(null)
    const [immigrationStatus, setImmigrationStatus] = useState()


    const [resumeData, setResumeData] = useState({
        last_name: "",
        city: "",
        state: "",
        availability: ""
    })

    useEffect(() => {
        fetch(
            `${OPEN_CATS_URL}/eventRouter/getSourceInfo`)
            .then((response) => response.json())
            .then((result) => {
                setSourceInfo(
                    result?.data?.map(x => {
                        return {
                            label: x.name, value: x.name
                        }
                    }))
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoader(false)
            });
    }, [])


    useEffect(() => {
        setView(false)
    }, [message])

    const changeHandler = (event) => {
        setSelectedFile(event.target.files);
        setView(false)
        setMessage(null)
    };


    const customAlert = () => {
        confirmAlert({
            title: 'Confirm to Cancel',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setView(false)
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log('Click No')
                }
            ]
        });

    }

    const handleSubmission = async () => {
        if (selectedFile !== undefined) {
            setLoader(true)
            setMessage("")
            const formData = new FormData();
            for (let i = 0; i < selectedFile.length; i++) {
                formData.append(`file`, selectedFile[i])
            }
            await fetch(
                `${PARSING_URL}/mass_upload`,
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    removeToken('emailType')
                    if (props.type !== 'Attach Candidate') {
                        checkForCandidate(result.parsed_files)
                    } else {
                        if (result.parsed_files[0].email_id !== "Invalid Email") {
                            checkForCandidate(result.parsed_files)
                        }
                    }
                    setData(result)
                    setView(true)
                    setLoader(false)
                })
                .catch((error) => {
                    sendErrorEmailAlert('parsing', getToken('user'), error)
                    setLoader(false)
                });
            setLoader(false)
        } else {
            setMessage("Please Select Resume")
        }

    };



    const handleImport = async (e, data) => {


        if (props.type === 'Attach Candidate') {
            e.preventDefault()

        }
        setLoader(true)
        setMessage(null)
        let resumes
        if (props.type === 'Attach Candidate') {
            resumes = data
        } else {
            resumes = data
        }
        if (resumeData.first_name !== "Invalid Name" && resumeData.email_id !== "Invalid Email" && resumeData.mobile_no !== "Invalid Mo. No") {
            await fetch(
                props.type !== 'Attach Candidate' ?
                    `${OPEN_CATS_URL}/insertionRouter/insert?id=${getToken('id')}`
                    :
                    `${OPEN_CATS_URL}/insertionRouter/insert?id=1`
                ,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        resumes: resumes,
                        resumeData: resumeData,
                        candidateSource: candidateSource !== 'Others' ? candidateSource : othersValue,
                        type: props.type === "Attach Candidate" ? {
                            post_type: "careers",
                            jobData: props.jobData
                        } : "rnats"

                    }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    setOthersOpen(false)
                    setOthersValue(null)
                    setResultData(result.result)
                    setResultView(true)
                    if (props.type !== 'Attach Candidate') {
                        setMessage(result.message)
                    } else {
                        setResetFile(!resetFile)
                        props.setMessage(result.message)
                        setSelectedFile()
                        setData()
                    }
                    if (props.type !== 'Attach Candidate') {
                        props.setInsertID(result.insertId)

                    }
                    setLoader(false)
                    setView(false)
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setMessage(error.message)
                    setLoader(false)
                });
            setLoader(false)
        } else {
            setLoader(false)
            setMessage("Please Fill Valid Details")
        }


    }

    useEffect(() => {
        // if (data?.parsed_files[0]?.mobile_no === "Invalid Name") {
        //     props.invalidResume(true)
        // } else {
        //     props.invalidResume(false)

        // }


        setResumeData({
            ...resumeData,
            first_name: data?.parsed_files[0].name,
            email_id: data?.parsed_files[0].email_id,
            mobile_no: data?.parsed_files[0].mobile_no
        })
        //eslint-disable-next-line
    }, [data])

    const handleChange = (e) => {
        setResumeData({
            ...resumeData, [e.target.name]: e.target.value
        })
    }

    function appendObjTo(thatArray, newObj) {
        const frozenObj = Object.freeze(newObj);
        return Object.freeze(thatArray.concat(frozenObj));
    }

    var sourceInfoData = sourceInfo.length > 0 ? appendObjTo(sourceInfo, { value: 'others', label: 'Others' }) : [{ value: 'others', label: 'Others' }]

    useEffect(() => {
        if (candidateSource === 'Others') {
            setOthersOpen(true)
        } else {
            setOthersOpen(false)
        }
    }, [candidateSource])

    useEffect(() => {
        if (props?.sub_type === "vendor_data") {
            setResumeData({
                ...resumeData,
                employer: props?.vendor_data?.company_name,
                employer_contact_name: `${props?.vendor_data?.first_name} ${props?.vendor_data?.last_name}`,
                employer_phone: props?.vendor_data?.mobile,
                employer_email: props?.vendor_data?.email,
            })
        }
    }, [props?.sub_type])

    const checkForCandidate = async (parsed_files) => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/insertionRouter/checkForCandidate`,
            {
                method: 'POST',
                body: JSON.stringify({ data: parsed_files }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.result[0].status === 'Duplicate Found') {
                    setResetFile(!resetFile)
                    props.setMessage("Candidate already submitted, Please submit new candidate")
                    setSelectedFile()
                    setData()
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoader(false)
            });
        setLoader(false)

    }

    return (
        <div>
            {/* <Modal
                isOpen={resultView}
                onRequestClose={() => {

                    setResultView(false)
                }}
                style={customStyles}
                shouldCloseOnOverlayClick={true}
            >
                <div>
                    <label>Resume Upload Status</label>
                    <table class="table table-striped my-table">
                        <thead>
                            <tr>
                                <th>File Name</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                resultData?.map((x, i) => {
                                    return (
                                        <tr row={i}>
                                            <td>{x.fileName}</td>
                                            <td>{x.result.first_name}</td>
                                            <td>{x.result.last_name}</td>
                                            <td>{x.result.email1}</td>
                                            <td>{x.result.phone_home}</td>
                                            <td style={x.status === 'Success' ? { color: 'green' } : { color: 'red' }}>
                                                {
                                                    x.status === "Success" ?
                                                        <svg className='p-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                                        </svg>
                                                        :
                                                        <svg className='p-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                        </svg>
                                                }
                                                <label className='p-2'>{x.status}</label>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>
                <center>
                    <button type="button" className='btn btn-outline-dark btn-sm' onClick={() => {
                        setResultView(false)
                    }}>
                        Close
                    </button>
                </center>
            </Modal> */}
            <LoadingOverlay
                active={loader}
                spinner
                text='Loading, Please Wait...'
            >
                <div className={props.type === "Attach Candidate" ? "container border p-5 mb-3" : "container border mt-5 p-5 mb-3"}>
                    <form >
                        <div className="form-group">
                            <label for="resumeFile">Upload Resume</label>
                            <input key={resetFile} required type="file" name="file" onChange={(e) => changeHandler(e)} className="form-control-file" id="resumeFile" />
                        </div>
                        <button type="button" disabled={selectedFile === undefined} onClick={() => handleSubmission()} class="btn btn-outline-dark btn-sm">Upload</button>
                    </form>
                    <br />
                    {message}
                </div>


                {
                    props?.type === 'Attach Candidate' &&
                    view === true &&
                    <div className="container border mt-5 p-1 mb-5">
                        <div>
                            <form onSubmit={(e) => {
                                e.preventDefault()
                                if (data?.parsed_files !== undefined) {
                                    setErrorMessage(null)
                                    handleImport(e, data?.parsed_files)
                                } else {
                                    setErrorMessage("Please upload resume before submit")
                                }
                            }}>
                                <div className='row'>
                                    <div className='col-4'>
                                        <label style={{ padding: '10px', marginTop: '10px' }}>First Name*</label>
                                    </div>
                                    <div className='col-6' style={{ padding: '10px' }}>
                                        <input name="first_name" className="form-control" value={resumeData.first_name} onChange={(e) => handleChange(e)} required />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-4'>
                                        <label style={{ padding: '10px', marginTop: '10px' }}>Last Name*</label>
                                    </div>
                                    <div className='col-6' style={{ padding: '10px' }}>
                                        <input name="last_name" className="form-control" onChange={(e) => handleChange(e)} required />

                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-4'>
                                        <label style={{ padding: '10px', marginTop: '10px' }}>Email*</label>
                                    </div>
                                    <div className='col-6' style={{ padding: '10px' }}>
                                        <input type="email" name="email_id" className="form-control" value={resumeData.email_id} onChange={(e) => handleChange(e)} required />

                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-4'>
                                        <label style={{ padding: '10px', marginTop: '10px' }}>Mobile*</label>
                                    </div>
                                    <div className='col-6' style={{ padding: '10px' }}>
                                        <input name="mobile_no" className="form-control" value={resumeData.mobile_no} onChange={(e) => handleChange(e)} required />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-4'>
                                        <label style={{ padding: '10px', marginTop: '10px' }}>City*</label>
                                    </div>
                                    <div className='col-6' style={{ padding: '10px' }}>
                                        <input name="city" className="form-control" onChange={(e) => handleChange(e)} required />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-4'>
                                        <label style={{ padding: '10px', marginTop: '10px' }}>State*</label>
                                    </div>
                                    <div className='col-6' style={{ padding: '10px' }}>
                                        <input name="state" className="form-control" onChange={(e) => handleChange(e)} required />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-4'>
                                        <label style={{ padding: '10px', marginTop: '10px' }}>Country*</label>
                                    </div>
                                    <div className='col-6' style={{ padding: '10px' }}>
                                        <input name="country" className="form-control" onChange={(e) => handleChange(e)} required />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-4'>
                                        <label style={{ padding: '10px', marginTop: '10px' }}>Key Skills*</label>
                                    </div>
                                    <div className='col-6' style={{ padding: '10px' }}>
                                        <input name="key_skill" className="form-control" onChange={(e) => handleChange(e)} required />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-4'>
                                        <label style={{ padding: '10px', marginTop: '10px' }}>Immigration Status*</label>
                                    </div>
                                    <div className='col-6' style={{ padding: '10px' }}>
                                        <select name="immigration_status" className="form-control" onChange={(e) => handleChange(e)} required >
                                            <option value="">Select One</option>
                                            <option value="H1B">H1B</option>
                                            <option value="Green Card EAD">Green Card EAD</option>
                                            <option value="Green Card">Green Card</option>
                                            <option value="OPT EAD">OPT EAD</option>
                                            <option value="TN">TN</option>
                                            <option value="Canadian Citizen">Canadian Citizen</option>
                                            <option value="Canada PR">Canada PR</option>
                                            <option value="H4 EAD">H4 EAD</option>
                                            <option value="Indian Citizen">Indian Citizen</option>
                                            <option value="Other">Other</option>
                                            <option value="USC">USC</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-4'>
                                        <label style={{ padding: '10px', marginTop: '10px' }}>Desired Pay*</label>
                                    </div>
                                    <div className='col-6' style={{ padding: '10px' }}>
                                        <input name="desired_pay" className="form-control" onChange={(e) => handleChange(e)} required />
                                    </div>
                                </div>
                                {
                                    props.sub_type !== "vendor_data" &&
                                    <div>
                                        <div className='row'>
                                            <div className='col-4'>
                                                <label style={{ padding: '10px', marginTop: '10px' }}>Employer Information</label>
                                            </div>
                                            <div className='col-6' style={{ padding: '10px' }}>
                                                <input name="employer" className="form-control" onChange={(e) => handleChange(e)} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-4'>
                                                <label style={{ padding: '10px', marginTop: '10px' }}>Employer Contact Name</label>
                                            </div>
                                            <div className='col-6' style={{ padding: '10px' }}>
                                                <input name="employer_contact_name" className="form-control" onChange={(e) => handleChange(e)} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-4'>
                                                <label style={{ padding: '10px', marginTop: '10px' }}>Employer Phone</label>
                                            </div>
                                            <div className='col-6' style={{ padding: '10px' }}>
                                                <input name="employer_phone" className="form-control" onChange={(e) => handleChange(e)} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-4'>
                                                <label style={{ padding: '10px', marginTop: '10px' }}>Employer Email</label>
                                            </div>
                                            <div className='col-6' style={{ padding: '10px' }}>
                                                <input name="employer_email" className="form-control" onChange={(e) => handleChange(e)} />
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className='row'>
                                    <div className='col-4'>
                                        <label style={{ padding: '10px', marginTop: '10px' }}>
                                            When are you available to start?
                                        </label>
                                    </div>
                                    <div className='col-6' style={{ padding: '10px' }}>
                                        <textarea name="availability" className="form-control" onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                                {errorMessage !== null &&
                                    <div style={{ padding: '1opx', color: 'red' }}>
                                        {errorMessage}
                                    </div>

                                }
                                <div className="row mt-2 ml-2">
                                    {
                                        // data.parsed_files[0].name !== "Invalid Name" ?
                                        <button className="btn btn-outline-dark mr-2"
                                            type="submit">
                                            Submit
                                        </button>
                                        // :
                                        // <button type="button" className="btn btn-outline-dark btn-sm mr-2">Please Upload Valid Resume</button>
                                    }

                                    <button className="btn btn-outline-dark" type="button" onClick={() => customAlert()}>Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                }{
                    (props?.type === 'activityData' && view) &&
                    <div className="container border mt-5 p-1 mb-5">
                        <form>
                            {/* <h5>Total Files: {data?.parsed_files_counts + data?.not_parsed_files_counts}</h5> */}
                            <div className="col-12 row mt-3 mb-3">
                                <div className="col-12">
                                    {/* <h5>Parsed File: # {data?.parsed_files_counts}</h5>
                                        <h5>Not Parsed File: # {data?.not_parsed_files_counts}</h5>
                                        <ul>
                                            {data.not_parsed_files.map(x => {
                                                return (
                                                    <li>{x}</li>
                                                )
                                            })}
                                        </ul> */}
                                    <div class="row justify-content-center" style={{ overflow: 'auto', fontSize: '12px' }}>

                                        <div class="col-auto">
                                            <table class="table table-hover table-sm">
                                                <thead>
                                                    <tr>
                                                        {/* <th>#</th> */}
                                                        <th>File Name</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Email</th>
                                                        <th>Cell Phone</th>
                                                        <th>Immigration Status</th>
                                                        <th>City</th>
                                                        <th>State</th>
                                                        <th>Country</th>
                                                        <th>Source</th>
                                                        <th>Primary Skills</th>
                                                        <th>Skills</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data?.parsed_files.map((x, i) => {
                                                            return (
                                                                <tr row={i}>
                                                                    {/* <td>{i + 1}</td> */}
                                                                    <td >{x.original_file_name}</td>
                                                                    <td ><input name="first_name" className="form-control" style={{ width: 'auto' }} value={resumeData.first_name} onChange={(e) => handleChange(e)} />
                                                                    </td>
                                                                    <td>
                                                                        <input name="last_name" className="form-control" style={{ width: 'auto' }} onChange={(e) => handleChange(e)} />
                                                                    </td>
                                                                    <td >
                                                                        <input name="email_id" className="form-control" style={{ width: 'auto' }} value={resumeData.email_id} onChange={(e) => handleChange(e)} />
                                                                    </td>
                                                                    <td >
                                                                        <input name="mobile_no" className="form-control" style={{ width: 'auto' }} value={resumeData.mobile_no} onChange={(e) => handleChange(e)} />
                                                                    </td>
                                                                    <td>
                                                                        <select style={{ width: 150 }} name="immigration_status" className="form-control" onChange={(e) => handleChange(e)} required >
                                                                            <option value="">Select One</option>
                                                                            <option value="H1B">H1B</option>
                                                                            <option value="Green Card EAD">Green Card EAD</option>
                                                                            <option value="Green Card">Green Card</option>
                                                                            <option value="OPT EAD">OPT EAD</option>
                                                                            <option value="TN">TN</option>
                                                                            <option value="Canadian Citizen">Canadian Citizen</option>
                                                                            <option value="Canada PR">Canada PR</option>
                                                                            <option value="H4 EAD">H4 EAD</option>
                                                                            <option value="Indian Citizen">Indian Citizen</option>
                                                                            <option value="Other">Other</option>
                                                                            <option value="USC">USC</option>

                                                                        </select>

                                                                    </td>
                                                                    <td>
                                                                        <input name="city" className="form-control" style={{ width: 'auto' }} onChange={(e) => handleChange(e)} />
                                                                    </td>
                                                                    <td>
                                                                        <input name="state" className="form-control" style={{ width: 'auto' }} onChange={(e) => handleChange(e)} />
                                                                    </td>
                                                                    <td>
                                                                        <input name="country" className="form-control" style={{ width: 'auto' }} onChange={(e) => handleChange(e)} />
                                                                    </td>
                                                                    <td>
                                                                        <Select
                                                                            styles={customStyles2}
                                                                            // value={selectedCandidate}
                                                                            onChange={(selectedOption) => setCandidateSource(selectedOption.label)}
                                                                            options={sourceInfoData}
                                                                        />
                                                                        {
                                                                            othersOpen ?
                                                                                <>
                                                                                    <br />
                                                                                    <input type="text" className='form-control' placeholder='Enter Source Info' onChange={e => setOthersValue(e.target.value)} />
                                                                                </>
                                                                                :
                                                                                null
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <input name="key_skill" className="form-control" style={{ width: 'auto' }} onChange={(e) => handleChange(e)} />
                                                                    </td>

                                                                    <td >
                                                                        <div className="skill" title={x.skills.join(', ')}>{x.skills.join(', ')}</div></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="row vertical-center mt-2">
                                    {
                                        // data?.parsed_files[0].name !== "Invalid Name" ?
                                        <button disabled={data?.parsed_files.length === 0} className="btn btn-outline-dark btn-sm mr-2" type="button" onClick={() => handleImport(null, data.parsed_files)}>
                                            Import
                                        </button>
                                        // :
                                        // <button type="button" className="btn btn-outline-dark btn-sm mr-2">Please Upload Valid Resume</button>
                                    }

                                    <button className="btn btn-outline-dark btn-sm" type="button" onClick={() => customAlert()}>Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div >

                }
            </LoadingOverlay >
        </div >


    );


}

// const customStyles = {
//     content: {
//         position: 'absolute',
//         inset: '40px',
//         border: '1px solid rgb(204, 204, 204)',
//         background: 'rgb(255, 255, 255)',
//         overflow: 'auto',
//         borderRadius: '4px',
//         outline: 'none',
//         padding: '20px',
//         width: '95%',
//         height: '90vh',
//         margin: 'auto'
//     }
// };

const customStyles2 = {
    container: provided => ({
        ...provided,
        width: 150
    })
};