import React, { useEffect, useState } from 'react'
import { OPEN_CATS_URL } from '../../config';
import { getToken } from '../../storage';
import Select, { createFilter } from 'react-select';
import SingleFileUpload from '../FileUpload/singleFileUpload'
import Modal from 'react-modal';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { FixedSizeList as List } from "react-window";
import AsyncSelect from 'react-select/async';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function JobOrder(props) {

    const [jobOrder, setJobOrder] = useState([])
    const [jobOrderId, setJobOrderId] = useState({})
    const [candidates, setCandidates] = useState([])
    const [createCandidate, setCreateCandidate] = useState(false)
    const [selectedCandidate, setSelectedCandidate] = useState(null)
    const [insertId, setInsertId] = useState()
    //eslint-disable-next-line
    const [isInvalidResume, setIsInvalidResume] = useState(false)
    const [loader, setLoader] = useState(false)
    const [message, setMessage] = useState()
    const [modalIsOpen, setIsOpen] = useState(false);
    const [users, setUsers] = useState([])
    const [editorState, setEditorState] = useState()
    const [company, setCompany] = useState([])
    const [description, setDescription] = useState()
    const [location, setLocation] = useState()
    const [workAuthorization, setWorkAuthorization] = useState()
    const [payRate, setPayRate] = useState()
    const [duration, setDuration] = useState()
    const [data, setData] = useState({
        status: "Internal",
        jobTitle: "",
        company: "",
        companyName: "",
        startDate: "",
        duration: "",
        jobType: "",
        billRate: "",
        payRate: "",
        city: "",
        state: "",
        work_authorization: "",
        recruiter: "",
        owner: "",
        keySkills: "",
        experience_needed: "",
        submissionDeadline: "",
        bdmComments: "",
        country: "USA",
        internalNotes: "",
        jobContact: -1,
        jobDepartment: "",
        client_job_id: "",
        source: "",
        openings: '1',
        priority: '2 - Do not work on this',
    })
    const [jobContacts, setJobContacts] = useState([])
    const [jobDepartment, setJobDepartment] = useState([])
    const [jobNotes, setJobNotes] = useState(null)
    const [candidateView, setCandidateView] = useState(false)
    const [loadOptionsData, setLoadOptionsData] = useState([])
    const [jobTypeData, setJobTypeData] = useState([])
    const [selectedJobType, setSelectedJobType] = useState()
    const [selectedJobOrderId, setSelectedJobOrderId] = useState()
    const [jobModal, setJobModal] = useState()
    const [companyData, setCompanyData] = useState()
    const [companyAdd, setCompanyAdd] = useState(false)
    const [companyContact, setCompanyContact] = useState(false)

    const [contactData, setContactData] = useState({
        company_id: "",
        last_name: "",
        first_name: "",
        title: "",
        email1: "",
        email2: "",
        phone_work: "",
        phone_cell: "",
        phone_other: "",
        address: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        is_hot: "",
        notes: "",
        company_department_id: "0",
        reports_to: "0"
    })
    const [departmentOptions, setDepartmentOptions] = useState([])
    const [reportsOptions, setReportsOptions] = useState([])

    useEffect(() => {
        setCandidateView(false)
        getJobTypesData()
    }, [])

    useEffect(() => {
        if (modalIsOpen === true) {
            getUsers()
            getCompanies()
        }

    }, [modalIsOpen])

    const getJobTypesData = async () => {
        setJobTypeData([])
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/searchRouter/getJobTypes`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setJobTypeData(result.data)
            })
            .catch((error) => {

                console.log('Error:', error.response);
                setLoader(false)
            });
        setLoader(false)
    }

    useEffect(() => {
        if (props.jobOrder === false) {
            setJobOrderId({})
            setCandidates([])
            setCreateCandidate(false)
            setSelectedCandidate(null)
            setInsertId()
            setIsInvalidResume(false)
            setLoader(false)
            setMessage()
        }
    }, [props])

    useEffect(() => {
        if (props.jobOrder) {
            getJobOrder()
        }
    }, [props.jobOrder])

    useEffect(() => {
        if (Object.keys(jobOrderId).length !== 0) {
            getCandidates()
        }
    }, [jobOrderId])

    const getJobOrder = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getAllJobOrder?id=${getToken('id')}`)
            .then((response) => response.json())
            .then((result) => {
                setJobOrder(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const getUsers = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getAllUsers`)
            .then((response) => response.json())
            .then((result) => {
                setUsers(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const getCompanies = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getCompany`)
            .then((response) => response.json())
            .then((result) => {
                setCompany(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }



    const getCandidates = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getCandidates?id=${getToken('id')}`)
            .then((response) => response.json())
            .then((result) => {
                setCandidates(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    var jobOrderData = jobOrder.map(x => {
        return {
            value: x.joborder_id, label: `NOVIJP00${x.joborder_id}-${x.title}`
        }
    })

    const handleSelect = (selectedOption, type) => {
        if (type === 'joborder') {
            if (selectedOption.value === 'newJob') {
                setIsOpen(true)
            } else {
                setIsOpen(false)
                setJobOrderId(selectedOption)
            }

        }
        if (type === "candidate") {
            if (selectedOption.value === 'newCandidate') {
                setCreateCandidate(true)
                setSelectedCandidate(selectedOption)
                setIsOpen(false)
            } else {
                setCandidateView(true)
                setSelectedCandidate(selectedOption)
                setCreateCandidate(false)
                setIsOpen(false)

            }
        }
    }

    var candidatesData = candidates.map(x => {
        return {
            value: x.candidate_id, label: `${x.first_name + x.last_name}`, data: x
        }
    })
    var newCandidatesData = candidates.length > 0 ? appendObjTo(candidatesData, { value: 'newCandidate', label: 'Add New Candidate' }) : [{ value: 'newCandidate', label: 'Add New Candidate' }]
    var newJobOrderData = jobOrderData.length > 0 ? appendObjTo(jobOrderData, { value: 'newJob', label: 'Add New Job Order' }) : [{ value: 'newJob', label: 'Add New Job Order' }]

    function reverseArr(input) {
        //eslint-disable-next-line
        var ret = new Array;
        for (var i = input.length - 1; i >= 0; i--) {
            ret.push(input[i]);
        }
        return ret;
    }
    function appendObjTo(thatArray, newObj) {
        const frozenObj = Object.freeze(newObj);
        return Object.freeze(thatArray.concat(frozenObj));
    }

    const handleAddJobPipeline = async () => {

        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/jobOrderPipeLine`,
            {
                method: 'POST',
                body: JSON.stringify({ addedBy: getToken('id'), candidateId: insertId || selectedCandidate.value, jobOrderId: jobOrderId.value }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setMessage(result.message)
                props.getMessage(result.message)
                props.setJobOrder()
                setLoader(false)
                setTimeout(() => {
                    window.location.reload()
                }, 3000)
            })
            .catch((error) => {
                props.getMessage('Failed to Add Candidate to Job Pipeline')
                setLoader(false)
            });
        setLoader(false)
    }

    function closeModal() {
        setIsOpen(false);
    }

    const onEditorStateChange = (editorState) => {

        setEditorState(editorState)
    }



    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleNewJob = async (e) => {
        e.preventDefault();
        if (editorState !== undefined) {
            var rawContentState = convertToRaw(editorState.getCurrentContent());
            var markup = draftToHtml(
                rawContentState,
            );
        }
        setMessage("")
        if (data.company != "") {
            if (selectedJobType?.length > 0) {
                setLoader(true)
                await fetch(
                    `${OPEN_CATS_URL}/eventRouter/createNewJobOrder`,
                    {
                        method: 'POST',
                        body: JSON.stringify({
                            addedBy: getToken('id'), markup: markup, data: data, selectedJobType, job_type_name: selectedJobType.map(x => {
                                return x.label
                            }).toString()
                        }),
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                )
                    .then((response) => response.json())
                    .then((result) => {
                        getJobOrder()
                        toast.success(result.message)
                        setJobOrderId({ value: data.joborder_id, label: data.title })
                        setIsOpen(false)
                        setData({
                            status: "Internal",
                            jobTitle: "",
                            company: "",
                            companyName: "",
                            startDate: "",
                            duration: "",
                            jobType: "",
                            billRate: "",
                            payRate: "",
                            city: "",
                            state: "",
                            work_authorization: "",
                            recruiter: "",
                            owner: "",
                            keySkills: "",
                            experience_needed: "",
                            submissionDeadline: "",
                            bdmComments: "",
                            country: "USA",
                            internalNotes: "",
                            jobContact: -1,
                            jobDepartment: "",
                            client_job_id: "",
                            source: "",
                            openings: '1',
                            priority: '2 - Do not work on this',
                        })
                        setEditorState()
                        getCompanies()
                    })
                    .catch((error) => {
                        props.getMessage('Failed to Add Candidate to Job Pipeline')
                        setLoader(false)
                    });
            } else {
                toast.warning("Please Select Job Type")
            }
        } else {
            toast.warning("Please Select Company")

        }
        setLoader(false)
    }

    useEffect(() => {
        if (Object.keys(jobOrderId).length !== 0) {
            setWorkAuthorization()
            setPayRate()
            var description = jobOrder.filter(y => { return y.joborder_id === jobOrderId.value })
            setDescription(description[0]?.description)
            setLocation(description[0]?.city)
            setDuration(description[0]?.duration)
            setJobNotes(description[0]?.notes)
            fetch(
                `${OPEN_CATS_URL}/eventRouter/getWorkAuthorization?job_id=${jobOrderId.value}`)
                .then((response) => response.json())
                .then((result) => {
                    setWorkAuthorization(result.data[0]?.value)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            fetch(
                `${OPEN_CATS_URL}/eventRouter/getPayRate`,
                {
                    method: 'POST',
                    body: JSON.stringify({ joborderId: jobOrderId.value }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    setPayRate(result.data[0]?.value)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
        //eslint-disable-next-line
    }, [jobOrderId])

    const getJobDepartment = async (companyId) => {
        setJobDepartment([])
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getJobDepartment?companyId=${companyId}`)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 200) {
                    setJobDepartment(result.data)
                }
            })
            .catch((error) => {
                setLoader(false)
                console.error('Error:', error);
            });
    }

    const getJobContacts = async (companyId) => {
        setJobContacts([])
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getJobContacts?companyId=${companyId}`)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 200) {
                    setJobContacts(result.data)
                }
            })
            .catch((error) => {
                setLoader(false)
                console.error('Error:', error);
            });
    }

    const MenuList = function MenuList(props) {
        const { options, children, maxHeight, getValue } = props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * 35;

        return (
            <List
                height={maxHeight}
                itemCount={children.length}
                itemSize={35}
                initialScrollOffset={initialOffset}
            >
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </List>
        );
    };


    const loadOptions = (inputValue) => {
        if (inputValue.length >= 3) {
            return fetch(`${OPEN_CATS_URL}/eventRouter/getCandidates?id=${getToken('id')}&access=${props?.access?.access_level}&value=${inputValue}`).then((response) => response.json())
                .then((result) => {
                    var d = result.data.map(x => {
                        return {
                            value: x.candidate_id, label: `${x.first_name + x.last_name}`, data: x
                        }
                    })
                    setLoadOptionsData(d)
                    return d
                })
        }
    };

    const handleOptionChange = value => {
        handleSelect(value, 'candidate')
    }

    const handleCompanyChange = (e) => {
        setCompanyData({
            ...companyData,
            [e.target.name]: e.target.value
        })
    }

    const addNewCompany = async (e) => {
        e.preventDefault()
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/companyRouter/createNewCompany`,
            {
                method: 'POST',
                body: JSON.stringify({ addedBy: getToken('id'), data: companyData }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                toast.info(result.message)
                getCompanies()
                setCompanyAdd(false)
            })
            .catch((error) => {
                setLoader(false)
            });
        setLoader(false)
    }

    const addNewContact = async (e) => {
        e.preventDefault()
        if (data.company_id === "0") {
            toast.info("Please Select Company")
        } else {
            setLoader(true)
            await fetch(
                `${OPEN_CATS_URL}/contactRouter/createNewContact`,
                {
                    method: 'POST',
                    body: JSON.stringify({ addedBy: getToken('id'), data: contactData }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    toast.info(result.message)
                    getJobContacts(data.company)
                    setCompanyContact(false)
                })
                .catch((error) => {
                    setLoader(false)
                });
            setLoader(false)
        }

    }

    const handleChangeContact = (e) => {
        setContactData({
            ...contactData,
            [e.target.name]: e.target.value
        })
    }

    const getDepartments = async (id) => {
        await fetch(
            `${OPEN_CATS_URL}/contactRouter/getDepartments?id=${id}`)
            .then((response) => response.json())
            .then((result) => {
                setDepartmentOptions(result.data.map(x => {
                    return {
                        label: x.name, value: x.company_department_id
                    }
                }))
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const getContacts = async (id) => {
        await fetch(
            `${OPEN_CATS_URL}/contactRouter/getContacts?id=${id}`)
            .then((response) => response.json())
            .then((result) => {
                setReportsOptions(result.data.map(x => {
                    return {
                        value: x.contact_id, label: `${x.first_name} ${x.last_name}`
                    }
                }))
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <div className="container">
            <ToastContainer />
            {
                props.jobOrder && (

                    <div>
                        <label style={{ color: 'green', fontSize: '18px', fontWeight: '500' }}>{message}</label>
                        {
                            jobOrder.length === 0 &&
                            <label style={{ color: 'black', fontSize: '18px', fontWeight: '500' }}>Loading...</label>

                        }
                        {
                            jobOrder.length > 0 && (
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Select Job Order*</label>
                                    <Select
                                        filterOption={createFilter({ ignoreAccents: false })}
                                        styles={customStyles2}
                                        // value={selectedCandidate}
                                        components={{ MenuList }}
                                        placeholder="Select Job Order"
                                        onChange={(selectedOption) => handleSelect(selectedOption, 'joborder')}
                                        options={reverseArr(newJobOrderData)} />
                                </div>
                            )
                        }

                        {
                            !modalIsOpen && jobOrderId.value && description !== "" && (
                                <div>

                                    <div><label style={{ fontWeight: 'bold' }}>Location: </label> <label>{location}</label></div>
                                    <div><label style={{ fontWeight: 'bold' }}>Duration: </label> <label>{duration}</label></div>
                                    <div><label style={{ fontWeight: 'bold' }}>Pay Rate: </label> <label>{payRate}</label></div>
                                    <div><label style={{ fontWeight: 'bold' }}>Work Authorization: </label> <label>{workAuthorization}</label></div>
                                    <label style={{ fontWeight: 'bold' }}>Job Description</label>
                                    <div style={{ borderStyle: 'solid', padding: '10px' }} className="mb-3" dangerouslySetInnerHTML={{ __html: description }}></div>
                                    <div><label style={{ fontWeight: 'bold' }}>Internal Notes: </label> <label>{jobNotes}</label></div>

                                </div>

                            )
                        }
                        {jobOrderId.label && newCandidatesData.length > 0 && (
                            <div className="form-group">
                                <label style={{ fontWeight: 'bold' }}>Select Candidate*</label>
                                <sub>{" "}(Enter more then 3 characters to search)</sub>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions={loadOptionsData}
                                    loadOptions={loadOptions}
                                    onChange={handleOptionChange}
                                />
                                {/* <Select
                                    filterOption={createFilter({ ignoreAccents: false })}
                                    styles={customStyles2}
                                    // value={selectedCandidate}
                                    components={{ MenuList }}
                                    placeholder="Select Candidate"
                                    onChange={(selectedOption) => handleSelect(selectedOption, 'candidate')}
                                    options={reverseArr(newCandidatesData)}
                                /> */}
                            </div>
                        )}
                        {
                            createCandidate && (
                                <SingleFileUpload invalidResume={(value) => setIsInvalidResume(value)} setInserID={(id) => setInsertId(id)} />
                            )
                        }
                        {
                            selectedCandidate !== null &&
                            <table className='table table-hove table-sm'>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>First Name: {selectedCandidate?.data?.first_name}</td>
                                        <td>Last Name: {selectedCandidate?.data?.last_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Email: {selectedCandidate?.data?.email1}</td>
                                        <td>Phone: {selectedCandidate?.data?.phone_home}</td>
                                    </tr>
                                    <tr>
                                        <td>Country: {selectedCandidate?.data?.country}</td>
                                        {/* <td>Key Skills: {selectedCandidate?.data?.key_skills}</td> */}
                                    </tr>
                                </tbody>
                            </table>
                        }
                        {
                            jobOrder.length > 0 &&
                            <button disabled={Object.keys(jobOrderId).length === 0} onClick={() => handleAddJobPipeline()} className="btn btn-outline-dark btn-sm">{loader ? 'Loading...' : 'Add Candidate to Job Pipeline'}</button>

                        }
                        <p className='mt-2'>
                            {message}
                        </p>
                    </div>
                )
            }

            <div>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    shouldCloseOnOverlayClick={false}
                >
                    <label>New Job Order</label>
                    <form className="form-group" onSubmit={(e) => handleNewJob(e)}>
                        <div className="form-group">
                            <button className="btn btn-danger btn-sm float-right" type="button" onClick={closeModal}>close</button>
                            <button className="btn btn-outline-dark btn-sm float-right mr-2" type="submit"  >Create Job</button>
                        </div>
                        <label>New Job Order</label>
                        <div className="row mt-3">
                            <div className="form-group col-4">
                                <label>Status*</label>
                                <select name="status" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => {
                                    if (e.target.value === 'Internal') {
                                        setData({
                                            ...data,
                                            openings: '1',
                                            priority: '2 - Do not work on this',
                                            status: 'Internal'
                                        })
                                        setSelectedJobType(jobTypeData.filter(x => {
                                            return x.job_type === "Bench Sales"
                                        }).map(x => {
                                            return {
                                                value: x.id, label: x.job_type
                                            }
                                        }))
                                    } else {
                                        setData({
                                            ...data,
                                            openings: '',
                                            priority: '',
                                            status: e.target.value
                                        })
                                        setSelectedJobType()
                                    }

                                }} required>
                                    <option selected={true} value="Active">Active</option>
                                    <option value="Active">Active</option>
                                    <option value="Upcoming">Upcoming</option>
                                    <option value="Lead">Prospective / Lead</option>
                                    <option value="OnHold">On Hold</option>
                                    <option value="Full">Full</option>
                                    <option value="Closed">Closed</option>
                                    <option value="Canceled">Canceled</option>
                                    <option value="Internal">Internal</option>
                                </select>
                            </div>
                            <div className="form-group col-4">
                                <label>Job Title*</label>
                                <input type="text" name="jobTitle" className="form-control form-control-sm" placeholder="Job Title" onChange={(e) => handleChange(e)} required />
                            </div>
                            <div className="form-group col-4">
                                <label>Company*</label><label onClick={() => setCompanyAdd(true)} style={{ fontSize: '10px', textDecoration: 'underline', cursor: 'pointer' }}>(Add New Company)</label>
                                <br />
                                <Select
                                    placeholder="Select Company"
                                    name="company"
                                    onChange={(selectedOption) => {
                                        setData({
                                            ...data,
                                            'company': selectedOption.value,
                                            'companyName': selectedOption.label
                                        })
                                        getJobDepartment(selectedOption.value)
                                        getJobContacts(selectedOption.value)
                                    }}
                                    options={company.sort((a, b) => a.name.localeCompare(b.name)).map(x => {
                                        return {
                                            value: x.company_id, label: x.name
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-4">
                                <label>Country*</label>
                                <input type="text" name="country" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Country" required={data.status !== 'Internal'} />
                            </div>
                            <div className="form-group col-4">
                                <label>State*</label>
                                <input type="text" name="state" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="State" required={data.status !== 'Internal'} />
                            </div>
                            <div className="form-group col-4">
                                <label>City*</label>
                                <input type="text" name="city" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="City" required={data.status !== 'Internal'} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-4">
                                <label>Job Source*</label>
                                <input type="text" name="source" className="form-control form-control-sm" placeholder="Job Source" onChange={(e) => handleChange(e)} required />
                            </div>
                            <div className="form-group col-4">
                                <label>Job Type*</label>
                                <Select
                                    isMulti
                                    value={selectedJobType}
                                    onChange={(selectedOption) => setSelectedJobType(selectedOption)}
                                    options={jobTypeData.map(x => {
                                        return {
                                            value: x.id, label: x.job_type
                                        }
                                    })}
                                />
                            </div>

                            <div className="form-group col-4">
                                <label>Contact</label><label onClick={() => {
                                    if (data.company !== "") {
                                        setContactData({
                                            ...contactData,
                                            company_id: data.company
                                        })
                                        getDepartments(data.company)
                                        getContacts(data.company)
                                        setCompanyContact(true)
                                    } else {
                                        toast.warn("Please Select Company")
                                    }

                                }} style={{ fontSize: '10px', textDecoration: 'underline', cursor: 'pointer' }}>{" "}(Add New Contact)</label>
                                <select name="jobContact" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => {
                                    handleChange(e)
                                }}>
                                    <option value=""></option>
                                    {jobContacts.map(x => {
                                        return (
                                            <option value={x.contact_id}>{x.first_name} {x.last_name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Description</label>
                            <Editor
                                editorStyle={{ borderStyle: 'solid', minHeight: '100px' }}
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={onEditorStateChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Comments To Vendor</label>
                            <textarea rows={1} name="vendorComments" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Comments to Vendor" />
                        </div>
                        <div className="row">
                            <div className="form-group col-3">
                                <label>Type</label>
                                <select name="jobType" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} >
                                    <option value="">Select One</option>
                                    <option value="C">C (Contract)</option>
                                    <option value="C2H">C2H (Contract To Hire)</option>
                                    <option value="FL">FL (Freelance)</option>
                                    <option value="H">(Hire)</option>
                                </select>
                            </div>
                            <div className="form-group col-3">
                                <label>Duration</label>
                                <input type="text" name="duration" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Duration" />
                            </div>
                            <div className="form-group col-3">
                                <label>Openings</label>
                                <input type="text" name="openings" value={data.openings} className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Openings" />
                            </div>
                            <div className="form-group col-3">
                                <label>Start Date</label>
                                <input type="date" name="startDate" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Start Date" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-4">
                                <label>Client Job ID</label>
                                <input type="text" name="client_job_id" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Client Job ID" />
                            </div>
                            <div className="form-group col-4">
                                <label>Bill Rate:</label>
                                <input type="text" name="billRate" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Bill Rate" />
                            </div>
                            <div className="form-group col-4">
                                <label>Pay Rate:</label>
                                <input type="text" name="payRate" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Pay Rate" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-4">
                                <label>Work Authorization</label>
                                <input type="text" name="work_authorization" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Work Authorization" />
                            </div>
                            <div className="form-group col-4">
                                <label>Recruiter*</label>
                                <select name="recruiter" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} required>
                                    <option value={props.access.user_id}>{props.access.user_name}</option>    {users.map(x => {
                                        return (
                                            <option value={x.user_id}>{x.user_name}-{x.last_name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="form-group col-4">
                                <label>Owner*</label>
                                <select name="owner" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} required>

                                    <option value={props.access.user_id}>{props.access.user_name}</option>
                                    {users.map(x => {
                                        return (
                                            <option value={x.user_id}>{x.user_name}-{x.last_name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-3">
                                <label>Priority*</label>
                                <select name="priority" value={data.priority} className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} required>
                                    <option selected={true} value="6 - Actively working">6 - Actively working</option>
                                    <option value="6 - Actively working">6 - Actively working</option>
                                    <option value="5 - Most Important">5 - Most Important</option>
                                    <option value="4 - Waiting for feedback">4 - Waiting for feedback</option>
                                    <option value="3 - Too late to submit">3 - Too late to submit</option>
                                    <option value="2 - Do not work on this">2 - Do not work on this</option>
                                    <option value="1 - Less Important">1 - Less Important</option>
                                </select>
                            </div>
                            <div className="form-group col-3">
                                <label>Job Department</label>
                                <select name="jobDepartment" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => {
                                    handleChange(e)
                                }}>
                                    <option value="">None</option>
                                    {jobDepartment.map(x => {
                                        return (
                                            <option key={x} value={x.company_department_id}>{x.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="form-group col-3">
                                <label>Hot*</label>
                                <select name="is_hot" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} required>
                                    <option selected={true} value="0">No</option>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </select>
                            </div>
                            <div className="form-group col-3">
                                <label>Public*</label>
                                <select name="public" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} required disabled={data?.status === 'Internal' ? true : false}>
                                    <option selected={true} value="0">No</option>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </select>
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-6">
                                <label>Experience Needed</label>
                                <textarea rows={1} name="experience_needed" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Experience Needed" />
                            </div>
                            <div className="form-group col-6">
                                <label>BDM Comments</label>
                                <textarea rows={1} name="bdmComments" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="BDM Comments" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-6">
                                <label>Submission Deadline</label>
                                <input type="text" name="submissionDeadline" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Submission Deadline" />
                            </div>
                            <div className="form-group col-6">
                                <label>Internal Notes</label>
                                <textarea rows={1} name="internalNotes" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Internal Notes" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Key Skills(Enter skills separated with comma(,))*</label>
                            <textarea rows={1} name="keySkills" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Key Skills" required={data.status !== 'Internal'} />
                        </div>
                        <div style={{ color: 'red' }}>
                            {message}
                        </div>
                        <div className="form-group">
                            <button className="btn btn-outline-dark btn-sm" type="submit"  >Create Job</button>
                        </div>
                    </form>
                </Modal>
            </div>
            <div>
                <Modal
                    isOpen={companyAdd}
                    onRequestClose={() => {
                    }}
                    style={customStyles}
                    shouldCloseOnOverlayClick={false}
                >
                    <label>New Company</label>
                    <button className="btn btn-danger float-right mb-2" onClick={() => {
                        setCompanyAdd(false)

                    }}>close</button>
                    <form className="form-group" onSubmit={(e) => addNewCompany(e)}>
                        <div className="form-group">
                            <label>Company Name*</label>
                            <input type="text" name="companyName" className="form-control form-control-sm mt-2 mb-2" placeholder="Company Name" onChange={(e) => handleCompanyChange(e)} required />
                        </div>
                        <div className="row">
                            <div className="form-group col-6">
                                <label>Primary Phone</label>
                                <input type="text" name="primaryPhone" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleCompanyChange(e)} placeholder="Primary Phone" />
                            </div>
                            <div className="form-group col-6">
                                <label>Secondary Phone</label>
                                <input type="text" name="secondaryPhone" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleCompanyChange(e)} placeholder="Secondary Phone" />
                            </div>
                        </div>

                        <div className="form-group">
                            <label>Address</label>
                            <textarea name="address" className="form-control form-control-sm mt-2 mb-2" placeholder="Address" onChange={(e) => handleCompanyChange(e)} />
                        </div>
                        <div className="row">
                            <div className="form-group col-4">
                                <label>Country*</label>
                                <input type="text" name="country" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleCompanyChange(e)} placeholder="State" />
                            </div>
                            <div className="form-group col-4">
                                <label>State*</label>
                                <input type="text" name="state" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleCompanyChange(e)} placeholder="State" />
                            </div>
                            <div className="form-group col-4">
                                <label>City*</label>
                                <input type="text" name="city" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleCompanyChange(e)} placeholder="City" />
                            </div>

                        </div>
                        <div className="row">
                            <div className="form-group col-6">
                                <label>Postal Code</label>
                                <input type="text" name="postalCode" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleCompanyChange(e)} placeholder="Postal Code" />
                            </div>
                            <div className="form-group col-6">
                                <label>Fax Number</label>
                                <input type="text" name="faxNumber" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleCompanyChange(e)} placeholder="Fax Number" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-6">
                                <label>Web Site</label>
                                <textarea name="website" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleCompanyChange(e)} placeholder="Web Site" />
                            </div>
                            <div className="form-group col-6">
                                <label>Hot Company</label>
                                <select name="isHot" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleCompanyChange(e)} >
                                    <option value="">Select One</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Notes</label>
                            <textarea name="notes" className="form-control form-control-sm mt-2 mb-2" placeholder="Notes" onChange={(e) => handleCompanyChange(e)} />
                        </div>
                        <div className="form-group">
                            <label>Key Technologies</label>
                            <textarea name="keyTechnologies" className="form-control form-control-sm mt-2 mb-2" placeholder="Key Technologies" onChange={(e) => handleCompanyChange(e)} />
                        </div>
                        <div className="form-group">
                            <button className="btn btn-outline-dark btn-sm" type="submit"  >Add Company</button>
                        </div>
                    </form>
                </Modal>
            </div>
            <div>
                <Modal
                    isOpen={companyContact}
                    onRequestClose={() => {
                    }}
                    style={customStyles}
                    shouldCloseOnOverlayClick={false}
                >
                    <label>New Company</label>
                    <button className="btn btn-danger float-right mb-2" onClick={() => {
                        setCompanyContact(false)
                    }}>close</button>
                    <form className="form-group" onSubmit={(e) => addNewContact(e)}>
                        <div className="row" style={{ marginTop: '15px' }}>
                            <div className="form-group col-6">
                                <label>First Name*</label>
                                <input type="text" name="first_name" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="First Name" required />
                            </div>
                            <div className="form-group col-6">
                                <label>Last Name*</label>
                                <input type="text" name="last_name" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="Last Name" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-6">
                                <label>Company*</label>
                                <input type="text" value={data.companyName} name="company_name" className="form-control form-control-sm mt-1 mb-2" readOnly />
                                {/* <ReactSearchAutocomplete
                                        styling={{
                                            zIndex: 99,
                                            borderRadius: 0
                                        }}
                                        items={items}
                                        onSearch={handleOnSearch}
                                        onHover={handleOnHover}
                                        onSelect={handleOnSelect}
                                        onFocus={handleOnFocus}
                                        autoFocus
                                        formatResult={formatResult}
                                    /> */}
                            </div>
                            <div className="form-group col-6">
                                <label>Title*</label>
                                <input type="text" name="title" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="Last Name" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-6">
                                <label>Department</label>
                                <Select
                                    placeholder="Select Department"
                                    onChange={(selectedOption) => setData({ ...data, company_department_id: selectedOption.value })}
                                    options={departmentOptions}
                                />
                            </div>
                            <div className="form-group col-6">
                                <label>Reports to</label>
                                <Select
                                    placeholder="Select Reporting Manager"
                                    onChange={(selectedOption) => setData({ ...data, reports_to: selectedOption.value })}
                                    options={reportsOptions}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-6">
                                <label>Email</label>
                                <input type="email" name="email1" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="Email" />
                            </div>
                            <div className="form-group col-6">
                                <label>2nd Email</label>
                                <input type="email" name="email2" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="2nd Email" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-4">
                                <label>Work Phone</label>
                                <input type="text" name="phone_work" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="Work Phone" />
                            </div>
                            <div className="form-group col-4">
                                <label>Cell Phone</label>
                                <input type="text" name="phone_cell" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="Cell Phone" />
                            </div>
                            <div className="form-group col-4">
                                <label>Other Phone</label>
                                <input type="text" name="phone_other" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="Other Phone" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Address</label>
                            <textarea name="address" className="form-control form-control-sm mt-2 mb-2" placeholder="Address" onChange={(e) => handleChangeContact(e)} />
                        </div>
                        <div className="row">
                            <div className="form-group col-4">
                                <label>City</label>
                                <input type="text" name="city" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="City" />
                            </div>
                            <div className="form-group col-4">
                                <label>State</label>
                                <input type="text" name="state" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="State" />
                            </div>
                            <div className="form-group col-4">
                                <label>Country</label>
                                <input type="text" name="country" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="Country" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-6">
                                <label>Postal Code</label>
                                <input type="text" name="zip" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="Postal Code" />
                            </div>
                            <div className="form-group col-6">
                                <label>Hot Contact</label>
                                <select name="is_hot" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} >
                                    <option value="">Select One</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Notes</label>
                            <textarea name="notes" className="form-control form-control-sm mt-2 mb-2" placeholder="Notes" onChange={(e) => handleChangeContact(e)} />
                        </div>
                        <div className="form-group">
                            <button className="btn btn-outline-dark btn-sm" type="submit"  >Add Contact</button>
                        </div>
                    </form>
                </Modal>
            </div>
        </div >

    )
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        height: '78%',
    },
};

const customStyles2 = {
    menu: (provided, state) => ({
        ...provided,
        minHeight: '300px',
        height: '300px',
    })
};