import React, { useEffect, useState } from 'react'
import { OPEN_CATS_URL } from '../../config';
import $ from 'jquery'
import moment from 'moment';
import Modal from 'react-modal';
import { getToken } from '../../storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import LoadingOverlay from 'react-loading-overlay';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Select from 'react-select';
import { useHistory } from 'react-router-dom';


export default function Contact(props) {
    const history = useHistory()

    const [contactData, setContactData] = useState([])
    const [loader, setLoader] = useState(false)
    const [contactAdd, setContactAdd] = useState(false)
    const [contactView, setContactView] = useState(false)
    const [companyData, setCompanyData] = useState([])
    const [departmentOptions, setDepartmentOptions] = useState([])
    const [reportsOptions, setReportsOptions] = useState([])
    const [searchView, setSearchView] = useState(false)
    const [data, setData] = useState({
        company_id: "",
        last_name: "",
        first_name: "",
        title: "",
        email1: "",
        email2: "",
        phone_work: "",
        phone_cell: "",
        phone_other: "",
        address: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        is_hot: "",
        notes: "",
        company_department_id: "0",
        reports_to: "0"
    })
    const [editData, setEditData] = useState({
        view: false,
        keyValue: null,
        data: {}
    })

    const [searchString, setSearchString] = useState({
        contactName: null,
        companyName: null,
        // title: null
    })

    useEffect(() => {
        if (contactView) {
            getAllContacts()
        }
    }, [contactView])
    useEffect(() => {
        if (contactAdd === true) {
            getAllCompanies()
        }
    }, [contactAdd])

    useEffect(() => {
        if (editData?.view === true) {
            getAllCompanies()
        }
    }, [editData?.view])

    const getAllCompanies = async () => {
        await fetch(
            `${OPEN_CATS_URL}/contactRouter/getAllCompanies`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setCompanyData(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        if (contactData.length > 0) {
            $(document).ready(function () {
                $('#companyTable tfoot th').each(function () {
                    var title = $(this).text();
                    $(this).html('<input type="text" placeholder="Search ' + title + '" />');
                });
                var table = window.$('#companyTable').DataTable({
                    "order": [[0, "desc"]],
                    mark: true,
                    bDestroy: true,
                })
                table.columns().every(function () {
                    var that = this;

                    $('input', this.footer()).addClass('input-class-footer').on('keyup change', function () {
                        if (that.search() !== this.value) {
                            that
                                .search(this.value)
                                .draw();
                        }
                    });
                });
                $('input.toggle-vis').on('change', function (e) {
                    e.preventDefault()
                    var columnIndex = parseInt($(this).attr('data-column'));
                    table.column(columnIndex).visible(!this.checked);
                });
            })
        }
    }, [contactData, new URLSearchParams(window.location.search).get('page')])

    const getAllContacts = async () => {
        setLoader(true)
        setContactData([])
        await fetch(
            `${OPEN_CATS_URL}/contactRouter/getAllContacts`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setContactData(result.data)

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const handleView = (type) => {
        if (type === 'add') {
            setContactAdd(true)
            setContactView(false)
            setSearchView(false)
        }
        if (type === 'view') {
            setContactAdd(false)
            setContactView(true)
            setSearchView(false)
        }
        if (type === 'search') {
            setContactAdd(false)
            setContactView(false)
            setSearchView(true)
        }
    }

    const addNewContact = async (e) => {
        e.preventDefault()
        if (data.company_id === "0") {
            toast.info("Please Select Company")
        } else {
            setLoader(true)
            await fetch(
                `${OPEN_CATS_URL}/contactRouter/createNewContact`,
                {
                    method: 'POST',
                    body: JSON.stringify({ addedBy: getToken('id'), data: data }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    toast.info(result.message)
                    handleView('view')
                })
                .catch((error) => {
                    setLoader(false)
                    handleView('view')
                });
            setLoader(false)
        }

    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const items = companyData?.map(x => { return { name: x.name, id: x.company_id } })


    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)

    }

    const handleOnHover = (result) => {
        // the item hovered
        setData({
            ...data,
            company_id: result.id

        })

    }

    const handleOnSelect = (item) => {
        setData({
            ...data,
            company_id: item.id

        })
        setEditData({
            ...editData,
            data: {
                ...editData.data,
                companyID: item.id
            }
        })
        getDepartments(item.id)
        getContacts(item.id)
    }

    const handleOnFocus = () => {
        console.log('Focused')
    }

    const formatResult = (item) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    const getContacts = async (id) => {
        await fetch(
            `${OPEN_CATS_URL}/contactRouter/getContacts?id=${id}`)
            .then((response) => response.json())
            .then((result) => {
                setReportsOptions(result.data.map(x => {
                    return {
                        value: x.contact_id, label: `${x.first_name} ${x.last_name}`
                    }
                }))
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getDepartments = async (id) => {
        await fetch(
            `${OPEN_CATS_URL}/contactRouter/getDepartments?id=${id}`)
            .then((response) => response.json())
            .then((result) => {
                setDepartmentOptions(result.data.map(x => {
                    return {
                        label: x.name, value: x.company_department_id
                    }
                }))
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        if (new URLSearchParams(window.location.search).get('type') === 'add') {
            handleView('add')
        }
        if (new URLSearchParams(window.location.search).get('type') === 'view') {
            handleView('view')
        }
        if (new URLSearchParams(window.location.search).get('type') === 'search') {
            handleView('search')
            setSearchString({
                contactName: null,
                companyName: null,
                // title: null
            })
        }
    }, [new URLSearchParams(window.location.search).get('type')])

    const createEdit = (data, keyValue) => {
        setEditData({
            view: true,
            keyValue: keyValue,
            data: data
        })
    }

    const handleUpdateContact = async (e) => {
        e.preventDefault()
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/contactRouter/updateContact`, {
            method: 'POST',
            body: JSON.stringify(editData),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                // setMessage(result.message)
                toast.info(result.message)
                setEditData({
                    view: false,
                    keyValue: null,
                    data: {}
                })
                getAllContacts()
            })
            .catch((error) => {
                setLoader(true)
                console.error('Error:', error);
            });

    }

    const handleEditChange = (e) => {
        setEditData({
            ...editData,
            data: {
                ...editData.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSearchContact = async (e) => {
        e.preventDefault()
        setContactData([])
        await fetch(
            `${OPEN_CATS_URL}/contactRouter/searchContact`, {
            method: 'POST',
            body: JSON.stringify(searchString),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setContactData(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    const handleSearhString = (e) => {
        setSearchString({
            ...searchString,
            [e.target.name]: e.target.value.length === 0 ? null : e.target.value
        })
    }

    return (
        props?.showContact && (
            <div className="ml-4">
                <ToastContainer />
                {/* <div className="mb-5">
                    <div className="mt-2 mb-2">
                        <button type="button" className={contactAdd ? "btn btn-outline-dark active mr-2 btn-sm" : "btn btn-outline-dark mr-2 btn-sm"} onClick={() => handleView('add')}>Add</button>
                        <button type="button" className={contactView ? "btn btn-outline-dark btn-sm active mr-2" : "btn btn-outline-dark btn-sm mr-2"} onClick={() => handleView('view')}>View</button>
                    </div>
                </div> */}
                <div style={{ textAlign: 'center' }}>
                    {
                        loader &&
                        <label style={{ color: 'black', fontSize: '18px', fontWeight: '500' }}>Loading...</label>

                    }
                </div>
                <div>
                    <div style={{ width: '90%', margin: 'auto', marginBottom: '10px', fontSize: '13px' }}>
                        {
                            searchView && (
                                <>
                                    <form onSubmit={e => handleSearchContact(e)}>
                                        <div>
                                            <label>Search Contacts</label>
                                        </div>
                                        <div className='col-10 row ncof'>
                                            <div className="col-3 ncofs">
                                                <input type="text" className='form-control form-control-sm nlfws' value={searchString.contactName === null ? '' : searchString.contactName} placeholder='Contact Name' name="contactName" onChange={(e) => handleSearhString(e)} />
                                            </div>
                                            <div className="col-3 ncofs">
                                                <input type="text" className='form-control form-control-sm nlfws' value={searchString.companyName === null ? '' : searchString.companyName} placeholder='Company Name' name="companyName" onChange={(e) => handleSearhString(e)} />
                                            </div>
                                            {/* <div className="col-3">
                                                <input type="text" className='form-control form-control-sm' placeholder='Title' name="title" onChange={(e) => handleSearhString(e)} />
                                            </div> */}
                                            <div className="col-3 ncofs">
                                                <button type='submit' className="btn btn-outline-dark btn-sm " >Search</button>
                                                {(searchString.companyName !== null || searchString.contactName !== null) && contactData.length > 0 &&
                                                    <button type='button' className="btn btn-outline-dark btn-sm ml-1" onClick={() => {
                                                        setSearchString({
                                                            contactName: null,
                                                            companyName: null,
                                                            // title: null
                                                        })
                                                        getAllContacts()

                                                    }}>Clear</button>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </>
                            )
                        }
                        {
                            contactData?.length > 0 ?
                                <div className="row justify-content-center" style={{ overflow: 'auto' }}>
                                    <div id="dataTable" className="col-auto sidebar">
                                        <label>Hide/Unhide Columns</label>
                                        <div style={{ fontSize: '10px' }}>
                                            <label><label><input type="checkbox" className="toggle-vis" data-column="1" /> </label><label className='m-1'>Action</label></label>
                                            <label><label><input type="checkbox" className="toggle-vis" data-column="2" /> </label><label className='m-1'>First Name</label></label>
                                            <label><label><input type="checkbox" className="toggle-vis" data-column="3" /> </label><label className='m-1'>Last Name</label></label>
                                            <label><label><input type="checkbox" className="toggle-vis" data-column="4" /> </label><label className='m-1'>Company</label></label>
                                            <label><label><input type="checkbox" className="toggle-vis" data-column="5" /> </label><label className='m-1'>Title</label></label>
                                            <label><label><input type="checkbox" className="toggle-vis" data-column="6" /> </label><label className='m-1'>Work Phone</label></label>
                                            <label><label><input type="checkbox" className="toggle-vis" data-column="7" /> </label><label className='m-1'>Department</label></label>
                                            <label><label><input type="checkbox" className="toggle-vis" data-column="8" /> </label><label className='m-1'>Email</label></label>
                                            <label><label><input type="checkbox" className="toggle-vis" data-column="9" /> </label><label className='m-1'>Cell Phone</label></label>
                                            <label><label><input type="checkbox" className="toggle-vis" data-column="10" /></label><label className='m-1'> Address</label></label>
                                            <label><label><input type="checkbox" className="toggle-vis" data-column="11" /></label><label className='m-1'> City</label></label>
                                            <label><label><input type="checkbox" className="toggle-vis" data-column="12" /></label><label className='m-1'> State</label></label>
                                            <label><label><input type="checkbox" className="toggle-vis" data-column="13" /></label><label className='m-1'> Country</label></label>
                                            <label><label><input type="checkbox" className="toggle-vis" data-column="14" /></label><label className='m-1'> Zip</label></label>
                                            <label><label><input type="checkbox" className="toggle-vis" data-column="15" /></label><label className='m-1'> Misc Notes</label></label>
                                            <label><label><input type="checkbox" className="toggle-vis" data-column="16" /></label><label className='m-1'> Owner</label></label>
                                            <label><label><input type="checkbox" className="toggle-vis" data-column="17" /></label><label className='m-1'> Created Date</label></label>
                                        </div>
                                        <br />
                                        <div className="">
                                            <table id="companyTable" className='  table table-hover table-sm rwd-table' style={{ width: '100%', overflow: 'auto' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ display: 'none' }}>contactId</th>
                                                        <th>Action</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Company</th>
                                                        <th>Title</th>
                                                        <th>Work Phone</th>
                                                        <th>Department</th>
                                                        <th>Email</th>
                                                        <th>Cell Phone</th>
                                                        <th>Address</th>
                                                        <th>City</th>
                                                        <th>State</th>
                                                        <th>Country</th>
                                                        <th>Zip</th>
                                                        <th>Misc Notes</th>
                                                        <th>Owner</th>
                                                        <th>Created Date</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th style={{ display: 'none' }}>contactId</th>
                                                        <th>Action</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Company</th>
                                                        <th>Title</th>
                                                        <th>Work Phone</th>
                                                        <th>Department</th>
                                                        <th>Email</th>
                                                        <th>Cell Phone</th>
                                                        <th>Address</th>
                                                        <th>City</th>
                                                        <th>State</th>
                                                        <th>County</th>
                                                        <th>Zip</th>
                                                        <th>Misc Notes</th>
                                                        <th>Owner</th>
                                                        <th>Created Date</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    {
                                                        contactData?.map((x, index) => {
                                                            return <tr key={x.name}>
                                                                <th style={{ display: 'none' }}>{x.contactId}</th>
                                                                <td data-th="Action">  <label style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                                                    createEdit(x, index)
                                                                }}>
                                                                    <i class="bi bi-pencil-square"></i>
                                                                </label>
                                                                </td>
                                                                <td data-th="First Name">{x.firstName}</td>
                                                                <td data-th="Last Name">{x.lastName}</td>
                                                                <td data-th="Company">{x.name}</td>
                                                                <td data-th="Title">{x.title}</td>
                                                                <td data-th="Work Phone">{x.workPhone}</td>
                                                                <td data-th="Department">{x.department}</td>
                                                                <td data-th="Email">{x.email1}</td>
                                                                <td data-th="Cell Phone">{x.cellPhone}</td>
                                                                <td data-th="Address">{x.address}</td>
                                                                <td data-th="City">{x.city}</td>
                                                                <td data-th="State">{x.state}</td>
                                                                <td data-th="Country">{x.extra_field_value0}</td>
                                                                <td data-th="Zip">{x.zip}</td>
                                                                <td data-th="Misc Notes">{x.notes}</td>
                                                                <td data-th="Owner">{`${x.ownerFirstName} ${x.ownerLastName}`}</td>
                                                                <td data-th="Created Date">{moment(x.dateCreatedSort).format('lll')}</td>
                                                                </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    <center className='mt-2'>
                                        No data Found
                                    </center>
                                </div>
                        }

                    </div>
                </div>
                <div>
                    <Modal
                        isOpen={contactAdd}
                        onRequestClose={() => {
                            history.push('/home?page=contact&type=view')
                            handleView('view')
                        }}
                        style={customStyles}
                        shouldCloseOnOverlayClick={false}
                    >
                        <label>New Contact</label>
                        <button className="btn btn-danger float-right mb-2" onClick={() => {
                            history.push('/home?page=contact&type=view')
                            handleView('view')
                        }}>close</button>
                        <form className="form-group " onSubmit={(e) => addNewContact(e)}>
                            <div className="row ncof" style={{ marginTop: '15px' }}>
                                <div className="form-group col-6 ">
                                    <label>First Name*</label>
                                    <input type="text" name="first_name" className="form-control mt-1 mb-2 nlf" onChange={(e) => handleChange(e)} placeholder="First Name" required />
                                </div>
                                <div className="form-group col-6">
                                    <label>Last Name*</label>
                                    <input type="text" name="last_name" className="form-control mt-1 mb-2 nlf" onChange={(e) => handleChange(e)} placeholder="Last Name" required />
                                </div>
                            </div>
                            <div className="row ncof">
                                <div className="form-group col-6 nlf">
                                    <label>Company*</label>
                                    <ReactSearchAutocomplete
                                        styling={{
                                            zIndex: 99,
                                            borderRadius: 0
                                        }}
                                        items={items}
                                        onSearch={handleOnSearch}
                                        onHover={handleOnHover}
                                        onSelect={handleOnSelect}
                                        onFocus={handleOnFocus}
                                        autoFocus
                                        formatResult={formatResult}
                                        className="nlf"
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label>Title*</label>
                                    <input type="text" name="title" className="form-control mt-1 mb-2 nlf" onChange={(e) => handleChange(e)} placeholder="Last Name" required />
                                </div>
                            </div>
                            <div className="row ncof">
                                <div className="form-group col-6 nlf">
                                    <label>Department</label>
                                    <Select
                                        placeholder="Select Department"
                                        onChange={(selectedOption) => setData({ ...data, company_department_id: selectedOption.value })}
                                        options={departmentOptions}
                                        className="nlf"
                                    />
                                </div>
                                <div className="form-group col-6 nlf">
                                    <label>Reports to</label>
                                    <Select
                                        placeholder="Select Reporting Manager"
                                        onChange={(selectedOption) => setData({ ...data, reports_to: selectedOption.value })}
                                        options={reportsOptions}
                                        className="nlf"
                                    />
                                </div>
                            </div>
                            <div className="row ncof">
                                <div className="form-group col-6">
                                    <label>Email</label>
                                    <input type="email" name="email1" className="form-control mt-1 mb-2 nlf" onChange={(e) => handleChange(e)} placeholder="Email" />
                                </div>
                                <div className="form-group col-6">
                                    <label>2nd Email</label>
                                    <input type="email" name="email2" className="form-control mt-1 mb-2 nlf" onChange={(e) => handleChange(e)} placeholder="2nd Email" />
                                </div>
                            </div>
                            <div className="row ncof">
                                <div className="form-group col-4 ">
                                    <label className='nlfw' >Work Phone</label>
                                    <input type="text" name="phone_work" className="form-control mt-1 mb-2 nlfw" onChange={(e) => handleChange(e)} placeholder="Work Phone" />
                                </div>
                                <div className="form-group col-4 ">
                                    <label className='nlfw'>Cell Phone</label>
                                    <input type="text" name="phone_cell" className="form-control mt-1 mb-2 nlfw" onChange={(e) => handleChange(e)} placeholder="Cell Phone" />
                                </div>
                                <div className="form-group col-4">
                                    <label className='nlfw'>Other Phone</label>
                                    <input type="text" name="phone_other" className="form-control mt-1 mb-2 nlfw" onChange={(e) => handleChange(e)} placeholder="Other Phone" />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Address</label>
                                <textarea name="address" className="form-control mt-2 mb-2 " placeholder="Address" onChange={(e) => handleChange(e)} />
                            </div>
                            <div className="row ncof">
                                <div className="form-group col-4 nlf">
                                    <label>City</label>
                                    <input type="text" name="city" className="form-control mt-1 mb-2 nlfw" onChange={(e) => handleChange(e)} placeholder="City" />
                                </div>
                                <div className="form-group col-4">
                                    <label>State</label>
                                    <input type="text" name="state" className="form-control mt-1 mb-2 nlfw" onChange={(e) => handleChange(e)} placeholder="State" />
                                </div>
                                <div className="form-group col-4">
                                    <label>Country</label>
                                    <input type="text" name="country" className="form-control mt-1 mb-2 nlfw" onChange={(e) => handleChange(e)} placeholder="Country" />
                                </div>
                            </div>
                            <div className="row ncof">
                                <div className="form-group col-6">
                                    <label>Postal Code</label>
                                    <input type="text" name="zip" className="form-control mt-1 mb-2 nlf" onChange={(e) => handleChange(e)} placeholder="Postal Code" />
                                </div>
                                <div className="form-group col-6">
                                    <label>Hot Contact</label>
                                    <select name="is_hot" className="form-control mt-1 mb-2 nlf" onChange={(e) => handleChange(e)} >
                                        <option value="">Select One</option>
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Notes</label>
                                <textarea name="notes" className="form-control mt-2 mb-2 " placeholder="Notes" onChange={(e) => handleChange(e)} />
                            </div>
                            <div className="form-group">
                                <button className="btn btn-outline-dark btn-sm" type="submit"  >Add Contact</button>
                            </div>
                        </form>
                    </Modal>
                </div>
                <div>
                    <Modal
                        isOpen={editData.view}
                        onRequestClose={() => {
                            history.push('/home?page=contact&type=view')
                            setEditData({
                                ...editData,
                                view: false
                            })
                        }}
                        style={customStyles}
                        shouldCloseOnOverlayClick={false}
                    >
                        <label>Update Contact</label>
                        <button className="btn btn-danger float-right mb-2" onClick={() => {
                            history.push('/home?page=contact&type=view')
                            setEditData({
                                ...editData,
                                view: false
                            })
                        }}>close</button>
                        <form className="form-group" onSubmit={(e) => handleUpdateContact(e)}>
                            <div className="row" style={{ marginTop: '15px' }}>
                                <div className="form-group col-6">
                                    <label>First Name*</label>
                                    <input type="text" value={editData.data.firstName} name="firstName" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="First Name" required />
                                </div>
                                <div className="form-group col-6">
                                    <label>Last Name*</label>
                                    <input type="text" value={editData.data.lastName} name="lastName" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="Last Name" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Company*</label>
                                    <ReactSearchAutocomplete
                                        styling={{
                                            zIndex: 99999,
                                            borderRadius: 0
                                        }}
                                        items={items}
                                        onSearch={handleOnSearch}
                                        onHover={handleOnHover}
                                        onSelect={handleOnSelect}
                                        onFocus={handleOnFocus}
                                        autoFocus
                                        formatResult={formatResult}
                                        placeholder={editData.data.name}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label>Title*</label>
                                    <input type="text" value={editData.data.title} name="title" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="Last Name" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Department</label>
                                    <Select
                                        placeholder={editData.data.department}
                                        onChange={(selectedOption) =>
                                            setEditData({
                                                ...editData,
                                                data: {
                                                    ...editData.data,
                                                    company_department_id: selectedOption.value
                                                }
                                            })}
                                        options={departmentOptions}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label>Reports to</label>
                                    <Select
                                        placeholder={editData.data.reports_to}
                                        onChange={(selectedOption) => setEditData({
                                            ...editData,
                                            data: {
                                                ...editData.data,
                                                reports_to: selectedOption.value
                                            }
                                        })}
                                        options={reportsOptions}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Email</label>
                                    <input type="email" value={editData.data.email1} name="email1" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="Email" />
                                </div>
                                <div className="form-group col-6">
                                    <label>2nd Email</label>
                                    <input type="email" value={editData.data.email2} name="email2" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="2nd Email" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-4">
                                    <label>Work Phone</label>
                                    <input type="text" value={editData.data.workPhone} name="workPhone" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="Work Phone" />
                                </div>
                                <div className="form-group col-4">
                                    <label>Cell Phone</label>
                                    <input type="text" value={editData.data.cellPhone} name="cellPhone" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="Cell Phone" />
                                </div>
                                <div className="form-group col-4">
                                    <label>Other Phone</label>
                                    <input type="text" value={editData.data.otherPhone} name="otherPhone" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="Other Phone" />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Address</label>
                                <textarea name="address" value={editData.data.address} className="form-control mt-2 mb-2" placeholder="Address" onChange={(e) => handleEditChange(e)} />
                            </div>
                            <div className="row">
                                <div className="form-group col-4">
                                    <label>City</label>
                                    <input type="text" value={editData.data.city} name="city" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="City" />
                                </div>
                                <div className="form-group col-4">
                                    <label>State</label>
                                    <input type="text" value={editData.data.state} name="state" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="State" />
                                </div>
                                <div className="form-group col-4">
                                    <label>Country</label>
                                    <input type="text" value={editData.data.extra_field_value0} name="extra_field_value0" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="Country" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Postal Code</label>
                                    <input type="text" value={editData.data.zip} name="zip" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} placeholder="Postal Code" />
                                </div>
                                <div className="form-group col-6">
                                    <label>Hot Contact</label>
                                    <select name="isHot" className="form-control mt-1 mb-2" onChange={(e) => handleEditChange(e)} >
                                        <option value={editData.data.isHot}>{editData.data.isHot === 1 ? "Yes" : "No"}</option>
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Notes</label>
                                <textarea name="notes" value={editData.data.notes} className="form-control mt-2 mb-2" placeholder="Notes" onChange={(e) => handleEditChange(e)} />
                            </div>
                            <div className="form-group">
                                <button className="btn btn-outline-dark btn-sm" type="submit"  >{loader ? "Loading.." : "Update Contact"}</button>
                            </div>
                        </form>
                    </Modal>
                </div>
            </div >
        )
    )
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        height: '70%'
    },
}