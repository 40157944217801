import React, { useEffect, useState } from 'react'
import { OPEN_CATS_URL, RANKING_URL } from '../../config'
import Navbar from '../Common/Navbar'
import moment from 'moment'
import Modal from 'react-modal';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { getToken, removeToken, setToken } from '../../storage';
import htmlToDraft from 'html-to-draftjs';
import $ from 'jquery'
import { confirmAlert } from 'react-confirm-alert'; // Import
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import EditCandidate from '../Candidates/editCandidate';
import { sendErrorEmailAlert } from '../Common/Alerts';

const Status = (props) => {
    const [count, setCount] = useState()

    useEffect(() => {
        getLatestStatus()
        //eslint-disable-next-line
    }, [props.key])

    const getLatestStatus = async (id) => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getLatestStatus`, {
            method: 'POST',
            body: JSON.stringify({ candidateId: props.candidateId, joborderId: props.joborderId }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setToken('previousStatusId', result.data[0].candidate_joborder_status_id)
                setCount(result.data[0].short_description)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <div>{count || "1.No Contact"}  </div>
    )
}

export default function SingleJobOrder(props) {

    const [jobData, setJobData] = useState([])
    const [payRate, setPayRate] = useState()
    const [immigrationStatus, setImmigrationStatus] = useState()
    const [experienceNeeded, setExperienceNeeded] = useState()
    const [loader, setLoader] = useState(false)
    const [jobEndDate, setJobEndDate] = useState()
    const [access, setAccess] = useState(0)
    const [priority, setPriority] = useState()
    const [jobPipeLine, setJobPipeLine] = useState([])
    const [jobSubmitted, setJobSubmitted] = useState([])
    const [bdmComments, setBdmComments] = useState()
    const [jobCountry, setJobCountry] = useState()

    const [modalIsOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState()
    const [editorState, setEditorState] = useState()

    const [jobContacts, setJobContacts] = useState([])
    const [jobDepartment, setJobDepartment] = useState([])
    const [company, setCompany] = useState([])
    const [users, setUsers] = useState([])

    const [jobUpdateData, setJobUpdateData] = useState()
    const [candidatePipelineData, setCandidatePipelineData] = useState([])
    const [jobPipeLineView, setJobPipeLineView] = useState(false)
    const [updatePipeLineData, setUpdatePipeLineData] = useState({})
    const [pipeLineData, setPipeLineData] = useState({
        status: "yes",
        logActivity: "yes",
        probability: "0"
    })
    const [candidateData, setCandidateData] = useState([])
    const [activityData, setActivityData] = useState([])
    const [candidateName, setCandidateName] = useState(null)
    const [user, setUser] = useState()
    const [pipeLineInfo, setPipeLineInfo] = useState([])
    const [activityInfo, setActivityInfo] = useState([])
    const [activityInfoView, setActivityInfoView] = useState(false)
    const [rankApiResult, setRankApiResult] = useState([])
    const [rankView, setRankView] = useState(false)
    const [selectCandidateCount, setSelectCandidateCount] = useState(5)
    const [candidateFromJobOrder, setCandidateFromJobOrder] = useState([])
    const [fileUrl, setFileUrl] = useState(null)
    const [isFileOpen, setIsFileOpen] = useState(false)
    const [candidateId, setCandidateId] = useState()
    const [jobTypeData, setJobTypeData] = useState([])
    const [selectedJobType, setSelectedJobType] = useState()
    const [existingJobType, setExistingJobType] = useState()
    const [candidateUpdatedData, setCandidateUpdatedData] = useState()

    const [skillOption, setSkillOption] = useState('Full Name')
    const [optionValue, setOptionValue] = useState(null)

    const [rankApiView, setRankApiView] = useState(false)
    const [advanceSearchView, setAdvanceSearchView] = useState(false)
    const [jobSource, setJobSource] = useState()


    useEffect(() => {
        getSingleJobDetail()
        candidatePipeLineInfo()
        getCandidatesJobOrderHistory()
        //eslint-disable-next-line
    }, [props.jobSlug])


    useEffect(() => {
        getPayRate()
        getImmigrationStatus()
        getExperienceNeeded()
        getJobEndDate()
        getPriority()
        getJobPipeLine()
        getJobSubmitted()
        getBdmComments()
        getJobCountry()
        getJobSource()
        getCompanies()
        getUsers()
        getJobTypesData()
    }, [jobData])

    const getJobTypesData = async () => {
        setJobTypeData([])
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/searchRouter/getJobTypes`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setJobTypeData(result.data)
            })
            .catch((error) => {

                console.log('Error:', error.response);
                setLoader(false)
            });
        setLoader(false)
    }

    const getUsers = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getAllUsers`)
            .then((response) => response.json())
            .then((result) => {
                setUsers(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const getCompanies = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getCompany`)
            .then((response) => response.json())
            .then((result) => {
                setCompany(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const getSingleJobDetail = async () => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getSingleJobDetail`, {
            method: 'POST',
            body: JSON.stringify({
                job_order_id: props.jobSlug
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setJobData(result)
            })
            .catch((error) => {
                console.log('Error:', error);
                setLoader(false)
            });
    }

    const getPayRate = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getPayRates`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: props.jobSlug
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setPayRate(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getImmigrationStatus = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getImmigrationStatus`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: props.jobSlug
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setImmigrationStatus(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getExperienceNeeded = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getExperienceNeeded`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: props.jobSlug
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setExperienceNeeded(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getPriority = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getPriority`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: props.jobSlug
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setPriority(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getJobEndDate = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getJobEndDate`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: props.jobSlug
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setJobEndDate(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getJobPipeLine = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getJobPipeLine`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: props.jobSlug
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setJobPipeLine(result)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getJobSubmitted = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getJobSubmitted`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: props.jobSlug
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setJobSubmitted(result)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getBdmComments = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getBdmComments`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: props.jobSlug
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setBdmComments(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getJobCountry = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getJobCountry`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: props.jobSlug
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setJobCountry(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getJobSource = async () => {
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getJobSource`,
            {
                method: 'POST',
                body: JSON.stringify({
                    job_order_id: props.jobSlug
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setJobSource(result[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const handleEdit = () => {
        setIsOpen(true)
        var secondArray = jobData[0].job_type?.split(',').map(x => {
            return { label: x, value: x }
        })


        var firstArray = jobTypeData?.map(x => {
            return {
                value: x.id, label: x.job_type
            }
        })

        var intersection = firstArray.filter(firstArrayItem =>
            secondArray?.some(
                secondArrayItem => firstArrayItem.label === secondArrayItem.label
            )
        );

        setSelectedJobType(intersection)
        setExistingJobType(intersection)

        const html = jobData[0]?.description !== null ? jobData[0]?.description : "";
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
        }

        getJobContacts(jobData[0]?.company_id)
        getJobDepartment(jobData[0]?.company_id)

        setJobUpdateData({
            jobTitle: jobData[0]?.title,
            company: jobData[0]?.company_id,
            startDate: moment(jobData[0]?.start_date).format('YYYY-MM-DD'),
            duration: jobData[0]?.duration,
            jobType: jobData[0]?.type,
            openings: jobData[0]?.openings,
            billRate: jobData[0]?.rate_max,
            payRate: payRate?.value,
            city: jobData[0]?.city,
            state: jobData[0]?.state,
            status: jobData[0]?.status,
            work_authorization: immigrationStatus?.value,
            recruiter: Number(jobData[0]?.created_id),
            owner: Number(jobData[0]?.owner_id),
            keySkills: jobData[0]?.keyskills,
            experience_needed: experienceNeeded?.value,
            submissionDeadline: jobEndDate?.value,
            priority: priority?.value,
            bdmComments: bdmComments?.value,
            source: jobSource?.value,
            country: jobCountry?.value,
            internalNotes: jobData[0]?.notes,
            jobContact: jobData[0]?.contact_id,
            jobDepartment: jobData[0]?.department_name,
            client_job_id: jobData[0]?.client_job_id,
            job_id: jobData[0]?.joborder_id,
            payRateId: payRate?.extra_field_id ? payRate?.extra_field_id : 0,
            workAuthorizationId: immigrationStatus?.extra_field_id ? immigrationStatus?.extra_field_id : 0,
            experienceNeededId: experienceNeeded?.extra_field_id ? experienceNeeded?.extra_field_id : 0,
            submissionDeadlineId: jobEndDate?.extra_field_id ? jobEndDate?.extra_field_id : 0,
            priorityId: priority?.extra_field_id ? priority?.extra_field_id : 0,
            bdmCommentsId: bdmComments?.extra_field_id ? bdmComments?.extra_field_id : 0,
            jobCountryId: jobCountry?.extra_field_id ? jobCountry?.extra_field_id : 0,
            is_hot: jobData[0]?.is_hot,
            public: jobData[0]?.public,
            vendorComments: jobData[0]?.vendor_comments
        })
    }
    const closeModal = () => {
        setIsOpen(false);
        setActivityInfoView(false)
    }
    const onEditorStateChange = (editorState) => {

        setEditorState(editorState)
    }
    const handleUpdateJobData = async (e) => {
        e.preventDefault();
        if (editorState !== undefined) {
            var rawContentState = convertToRaw(editorState.getCurrentContent());
            var markup = draftToHtml(
                rawContentState,
            );
        }
        setMessage("")

        if (selectedJobType?.length > 0) {
            setLoader(true)
            await fetch(
                `${OPEN_CATS_URL}/eventRouter/updateJobOrder`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        addedBy: getToken('id'), markup: markup, data: jobUpdateData, selectedJobType, job_type_name: selectedJobType.map(x => {
                            return x.label
                        }).toString(),
                        existing_job_type: existingJobType
                    }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    toast.success(result.message)
                    setIsOpen(false)
                    removeToken('joborderData')
                    getSingleJobDetail()
                    candidatePipeLineInfo()
                    getCandidatesJobOrderHistory()
                })
                .catch((error) => {

                    setLoader(false)
                });
        } else {
            toast.warning("Please Select Job Type")
        }
        setLoader(false)

    }
    const handleChange = (e) => {
        setJobUpdateData({
            ...jobUpdateData,
            [e.target.name]: e.target.value
        })
    }
    const getJobDepartment = async (companyId) => {
        setJobDepartment([])
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getJobDepartment?companyId=${companyId}`)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 200) {
                    setJobDepartment(result.data)
                }
            })
            .catch((error) => {
                setLoader(false)
                console.error('Error:', error);
            });
    }
    const getJobContacts = async (companyId) => {
        setJobContacts([])
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getJobContacts?companyId=${companyId}`)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 200) {
                    setJobContacts(result.data)
                }
            })
            .catch((error) => {
                setLoader(false)
                console.error('Error:', error);
            });
    }



    const candidatePipeLineInfo = async () => {
        setCandidatePipelineData([])
        // if (loader) {
        //     window.$('#candidatePipelineTable').DataTable().clear().destroy();
        // }
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/candidatePipeLineInfo?jobId=${props.jobSlug}`,
        )
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setCandidatePipelineData(result.data)
                if (!loader) {
                    $(document).ready(function () {
                        window.$('#candidatePipelineTable').DataTable(
                            {
                                "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                                "order": [[3, "desc"]],
                                dom: 'Bfrtip',
                                mark: true,
                                "bDestroy": true,
                                buttons: [
                                    'pageLength',
                                    'selectAll',
                                    'selectNone',
                                    {

                                        extend: 'excelHtml5',
                                        autoFilter: true,
                                        sheetName: 'Exported data',
                                        text: 'Export'

                                    }],
                                select: true
                            }
                        )
                    })
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoader(false)
            });
    }

    const getCandidatesJobOrderHistory = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getCandidateFromJobOrderHistory`, {
            method: 'POST',
            body: JSON.stringify({
                jobOrderId: props.jobSlug
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {



                setCandidateFromJobOrder(result.data)
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }

    const handleUpdateJobOrder = (candidateId, joborderId, title, status, name) => {



        setCandidateName(name)
        setJobPipeLineView(true)

        setUpdatePipeLineData({
            candidateId: candidateId,
            joborderId: joborderId,
            title: title
        })
        setPipeLineData({
            ...pipeLineData,
            candidateId: candidateId,
            joborderId: joborderId,
            title: title,
            created_id: getToken('id')
        })

    }
    function closePipeLineModal() {
        setJobPipeLineView(false)
        setPipeLineData({
            status: "yes",
            logActivity: "yes",
            probability: "0",
            candidateStatus: ""
        })
    }

    const handlePipeLine = (e) => {
        setPipeLineData({
            ...pipeLineData, [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        if (jobPipeLineView) {
            getCandidateStatus()
            getActivityType()
        }
        //eslint-disable-next-line
    }, [jobPipeLineView])

    const getCandidateStatus = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getJobOrderStatus`)
            .then((response) => response.json())
            .then((result) => {
                setCandidateData(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const getActivityType = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getActivityType`)
            .then((response) => response.json())
            .then((result) => {
                setActivityData(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (pipeLineData.candidateStatus === "400") {
            if (pipeLineData.probability === '0') {
                toast.warning("choose closing probability")
                return false
            }
        }

        let previousStatus = (getToken('previousStatusId'))

        await fetch(
            `${OPEN_CATS_URL}/eventRouter/updateJobPipeLine`, {
            method: 'POST',
            body: JSON.stringify({ pipeLineData: pipeLineData, previousStatus: previousStatus }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                closePipeLineModal()
                getSingleJobDetail()
                candidatePipeLineInfo()
                getCandidatesJobOrderHistory()

                // getCandidateActivity()
                // getCandidatePipeLines()
                setMessage(result.message)
                candidateName(null)

            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    function openModal(x) {
        setUser(x)
        getCandidateActivity(x)
        getCandidatePipeLines(x)
        getSingleCandidate(x.candidate_id)

    }

    const getSingleCandidate = async (id) => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getSingleCandidate`, {
            method: 'POST',
            body: JSON.stringify({ candidate_id: id }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {

                setCandidateUpdatedData(result.data)
                setActivityInfoView(true)
                setLoader(false)
            })
            .catch((error) => {
                setLoader(false)
                console.error('Error:', error);
            });
    }

    const getCandidatePipeLines = async (data) => {
        setPipeLineInfo([])
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getCandidatePipeLines`,
            {
                method: 'POST',
                body: JSON.stringify({ candidateId: data.candidateID }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setPipeLineInfo(result.data)

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const getCandidateActivity = async (data) => {
        setActivityInfo([])
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getCandidateActivity`,
            {
                method: 'POST',
                body: JSON.stringify({ candidateId: data.candidateID }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                setActivityInfo(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    const getData = (data) => {
        if (data) {
            return (<p dangerouslySetInnerHTML={{ __html: data.concat(" ") }} />)
        }
    }

    const customAlert = (candidateId, jobOrderId) => {
        confirmAlert({
            title: '',
            message: 'Are you sure want to remove candidate from joborder pipeline.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        handleRemoveJobOrder(candidateId, jobOrderId)
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log('Click No')
                }
            ]
        });

    }

    const handleRemoveJobOrder = async (candidateId, jobOrderId) => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/removeCandidateFromPipeline`,
            {
                method: 'POST',
                body: JSON.stringify({ candidateId: candidateId, jobOrderId: jobOrderId }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((response) => response.json())
            .then((result) => {
                getSingleJobDetail()
                candidatePipeLineInfo()
                getCandidatesJobOrderHistory()

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        if (rankApiResult.length > 0) {
            $(document).ready(function () {
                window.$('#candidateRankTable').DataTable(
                    {
                        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                        "order": [[4, "asc"]],
                        // dom: 'Bltip',
                        mark: true,
                        "bDestroy": true
                        // destroy: true,
                        // buttons: [
                        //     'pageLength',
                        //     'selectAll',
                        //     'selectNone',
                        //     {

                        //         extend: 'excelHtml5',
                        //         autoFilter: true,
                        //         sheetName: 'Exported data',
                        //         text: 'Export'

                        //     }],
                        // select: true
                    }
                )
            })
        }
    }, [rankApiResult, rankView])


    const handleAdvanceSearch = async () => {

        setLoader(true)
        setRankApiResult([])
        await fetch(
            `${OPEN_CATS_URL}/searchRouter/advanceSearch`, {
            method: 'POST',
            body: JSON.stringify({
                "option_value": optionValue,
                "skill_option": skillOption
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json())
            .then((result) => {
                setRankApiResult(result.data)
                setLoader(false)
            })
            .catch((error) => {
                console.error('Error:', error);
            });


    }

    const handleRankSearchApi = () => {
        if (rankApiResult.length > 0) {
            setRankView(true)
            setLoader(false)
        } else {
            setRankApiResult([])
            setLoader(true)
            // setRankView(false)
            if (loader) {
                window.$('#candidateRankTable').DataTable().clear().destroy();
            }
            fetch(
                `${RANKING_URL}/spark_rank_api_v4`, {
                method: 'POST',
                body: JSON.stringify({
                    "title": "NULL",
                    "country": "NULL",
                    "state": "NULL",
                    "city": "NULL",
                    // "country": jobData[0]?.country !== null && jobData[0]?.country !== undefined ? jobData[0]?.country : "NULL",
                    // "state": jobData[0]?.state !== null && jobData[0]?.state !== undefined ? jobData[0]?.state : "NULL",
                    // "city": jobData[0]?.city !== null && jobData[0]?.city !== undefined ? jobData[0]?.city : "NULL",
                    "skills": jobData[0]?.keyskills.split(','),
                    "top": 100
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((response) => response.json())
                .then((result) => {
                    removeToken('emailType')
                    setLoader(false)
                    // setRankView(true)
                    handleSaveSearch(jobData[0]?.keyskills, JSON.stringify(result))
                    //Find values that are in result1 but not in result2
                    // var uniqueResultOne = candidatePipelineData.filter(function (obj) {
                    //     return !result.some(function (obj2) {
                    //         return obj.candidate_id === obj2.candidate_id;
                    //     });
                    // });

                    let arr1 = candidateFromJobOrder.map(x => {
                        return { candidate_id: x.candidate_id }
                    })
                    let arr2 = candidatePipelineData.map(x => {
                        return { candidate_id: x.candidate_id }
                    })

                    // console.log(arr1)
                    // console.log(arr2)

                    let newArray = arr2.concat(arr1)

                    // console.log(newArray)

                    //Find values that are in result2 but not in result1
                    var uniqueResultTwo = result.filter(function (obj) {
                        return !newArray.some(function (obj2) {
                            return obj.candidate_id === obj2.candidate_id;
                        });
                    });

                    //Combine the two arrays of unique entries
                    var resultArray = uniqueResultTwo
                    setRankApiResult(resultArray)

                })
                .catch((error) => {
                    console.error('Error:', error);
                    sendErrorEmailAlert('ranking', getToken('user'), error)
                    setLoader(false)
                    setRankApiResult([])
                    // setRankView(true)
                });
        }

    }

    const addCandidateJobOrder = (value, type) => {
        if (type === "single") {
            fetch(
                `${OPEN_CATS_URL}/eventRouter/addSingleRankJobOrder`, {
                method: 'POST',
                body: JSON.stringify({
                    candidateId: value,
                    enteredBy: getToken('id'),
                    jobOrderId: props.jobSlug
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((response) => response.json())
                .then((result) => {
                    setLoader(false)
                    toast.info(result.message)
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoader(false)

                });
        }
        if (type === 'multiple') {
            fetch(
                `${OPEN_CATS_URL}/eventRouter/addMultipleRankJobOrder`, {
                method: 'POST',
                body: JSON.stringify({
                    count: value,
                    enteredBy: getToken('id'),
                    candidateData: rankApiResult,
                    jobOrderId: props.jobSlug
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((response) => response.json())
                .then((result) => {
                    setLoader(false)
                    toast.info(result.message)
                    setRankView(false)
                    setTimeout(() => {
                        getSingleJobDetail()
                        candidatePipeLineInfo()
                        getCandidatesJobOrderHistory()
                    }, 2000)
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoader(false)

                });
        }
    }

    const getValidUrl = (url) => {

        if (url.indexOf("http") === 0) {
            return true
        } else {
            return false
        }
    };

    const downloadUrl = (url, candidate_id) => {

        const newUrl = new URL(url);
        if (newUrl.origin === 'http://nats.novisync.com') {
            window.open(newUrl, '_blank').focus();
            props.closeResume()
        } else {
            setFileUrl(`https://docs.google.com/gview?url=${url}&embedded=true`)
            setIsFileOpen(true)
            setCandidateId(candidate_id)
        }
    }


    const refreshModal = () => {
        var iframe = document.getElementById('gview');
        iframe.src = iframe.src;
    }

    useEffect(() => {
        if (jobData[0]?.keyskills !== null && jobData[0]?.keyskills !== undefined)
            handleGetSearchData()
    }, [jobData[0]?.keyskills])

    const handleGetSearchData = async () => {
        await fetch(
            `${OPEN_CATS_URL}/searchRouter/getSearchBatchDataSkill?skill=${jobData[0]?.keyskills}`)
            .then((response) => response.json())
            .then((result) => {
                let resultData = JSON.parse(result?.data[0].data)
                setLoader(false)
                let arr1 = candidateFromJobOrder.map(x => {
                    return { candidate_id: x.candidate_id }
                })
                let arr2 = candidatePipelineData.map(x => {
                    return { candidate_id: x.candidate_id }
                })


                let newArray = arr2.concat(arr1)

                //Find values that are in result2 but not in result1
                var uniqueResultTwo = resultData?.filter(function (obj) {
                    return !newArray.some(function (obj2) {
                        return obj.candidate_id === obj2.candidate_id;
                    });
                });

                //Combine the two arrays of unique entries
                var resultArray = uniqueResultTwo
                setRankApiResult(resultArray)
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoader(false)
            });
    }

    const handleSaveSearch = async (searchString, data) => {
        // setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/searchRouter/addSearchQuery`,
            {
                method: 'POST',
                body: JSON.stringify({
                    searchString: searchString,
                    data: data
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        ).then((response) => {
            console.log(response)
        })
            .then(() => {
                setLoader(false)
                handleGetSearchData()
            }).catch((error) => {
                // Handle the error
                console.log(error)
            })
        setLoader(false)
    }

    return (
        <div>
            <div style={{ display: 'none' }}>
                <Navbar getAccess={(data) => setAccess(data)} />
            </div>
            <ToastContainer style={{ zIndex: 999999999999 }} />
            <div className='container' style={{ marginTop: '3%', fontSize: '13px', marginBottom: '50px' }}>
                <div className='row'>
                    <button disabled={jobData?.length === 0} className='btn btn-outline-info btn-sm mr-2' onClick={() => handleEdit()}>Edit</button>
                    <button className='btn btn-outline-danger btn-sm mr-2' onClick={() => props.closeModal()}>Close</button>
                </div>
                <div className='mt-2 mb-2'>
                    <div className='row'>
                        <div className='col-4'>
                            <div className='row' style={{ padding: '5px' }}>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Title</div>
                                <div className='col-6' style={{ padding: '10px', color: jobData[0]?.is_hot === 1 ? 'red' : 'black' }}>{jobData[0]?.title}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Job Type</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobData[0]?.job_type}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Company Name</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobData[0]?.name}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Department</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobData[0]?.department_name}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>CATS Job ID</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobData[0]?.joborder_id}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Company Job ID</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobData[0]?.client_job_id}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Contact Name</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobData[0]?.contact_first_name} {jobData[0]?.contact_last_name}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Contact Phone</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobData[0]?.contact_phone}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Contact Email</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobData[0]?.contact_email}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Submission Deadline</div>
                                <div className='col-6' style={{ padding: '10px' }}> {jobEndDate?.value}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Priority</div>
                                <div className='col-6' style={{ padding: '10px' }}>{priority?.value}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Pay Rate</div>
                                <div className='col-6' style={{ padding: '10px' }}>{payRate?.value}</div>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='row' style={{ padding: '10px' }}>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Duration</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobData[0]?.duration}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Openings</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobData[0]?.openings}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Type</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobData[0]?.type}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Status</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobData[0]?.status}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Pipeline</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobPipeLine?.length > 0 ? jobPipeLine?.length : 0}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Experience Needed</div>
                                <div className='col-6' style={{ padding: '10px' }}>{experienceNeeded?.value}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>BDM Comments</div>
                                <div className='col-6' style={{ padding: '10px' }}>{bdmComments?.value}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Country</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobCountry?.value}</div>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='row' style={{ padding: '10px' }}>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Submitted</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobSubmitted?.length > 0 ? jobSubmitted?.length : 0}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Days Old</div>
                                <div className='col-6' style={{ padding: '10px' }}>{moment(jobData[0]?.date_created).fromNow()}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Created</div>
                                <div className='col-6' style={{ padding: '10px' }}>{moment(jobData[0]?.date_created).format('MM-DD-YYYY')}{" "}({moment(jobData[0]?.date_created).format('h:mm')}){" "}({jobData[0]?.createdFirstName} {jobData[0]?.createdLastName})</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Recruiter</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobData[0]?.recruiterFirstName} {jobData[0]?.recruiterLastName}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Owner</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobData[0]?.ownerFirstName} {jobData[0]?.ownerLastName}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Location</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobData[0]?.city}, {jobData[0]?.state}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Max Rate</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobData[0]?.rate_max}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Salary</div>
                                <div className='col-6' style={{ padding: '10px' }}>{jobData[0]?.salary}</div>
                                <div className='col-6' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>Start Date</div>
                                <div className='col-6'>{jobData[0]?.start_date !== null ? moment(jobData[0]?.start_date).format('MM-DD-YYYY') : null}</div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-2' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>
                            Attachments
                        </div>
                        <div className='col-8' style={{ padding: '10px' }}>

                        </div>
                    </div>
                    <div className='row'>

                        <div className='col-2' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>
                            Description
                        </div>
                        <div className='col-8' style={{ border: 'solid 1px black', padding: '10px' }}>
                            <div dangerouslySetInnerHTML={{ __html: jobData[0]?.description }}>
                            </div>
                        </div>
                        {/* <div className='col-4' style={{ border: 'solid 1px black', padding: '10px' }}>
                            <iframe title='jobOrderPipeline' src={`https://nats.novisync.com/index.php?m=reports&a=graphView&theImage=index.php%3Fm%3Dgraphs%26a%3DminiJobOrderPipeline%26width%3D640%26height%3D480%26params%3D1037`} />
                    </div> */}
                    </div>
                    <div className='row'>

                        <div className='col-2' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>
                            Work Authorization
                        </div>
                        <div className='col-8' style={{ padding: '10px' }}>
                            {immigrationStatus?.value}
                        </div>
                    </div>
                    <div className='row'>

                        <div className='col-2' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>
                            Internal Notes
                        </div>
                        <div className='col-8' style={{ padding: '10px' }}>
                            {jobData[0]?.notes}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-2' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>
                            Key Skills
                        </div>
                        <div className='col-8' style={{ padding: '10px' }}>
                            {jobData[0]?.keyskills}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-2' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>
                            Vendor Comments
                        </div>
                        <div className='col-8' style={{ padding: '10px' }}>
                            {jobData[0]?.vendor_comments}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-2' style={{ backgroundColor: '#F4F4F4', padding: '10px' }}>
                            Source
                        </div>
                        <div className='col-8' style={{ padding: '10px' }}>
                            {jobSource?.value}
                        </div>
                    </div>
                </div>
                <div>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles5}
                        shouldCloseOnOverlayClick={false}
                    >
                        <label>Update Job Order</label>
                        <button className="btn btn-danger float-right mb-2" onClick={closeModal}>close</button>
                        <form className="form-group" onSubmit={(e) => handleUpdateJobData(e)}>
                            <div className="form-group mt-4">

                            </div>
                            <div className="row">
                                <div className="form-group col-4">
                                    <label>Status*</label>
                                    <select name="status" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} required>
                                        <option value={jobData[0]?.status}>{jobUpdateData?.status}</option>
                                        <option value="Active">Active</option>
                                        <option value="Upcoming">Upcoming</option>
                                        <option value="Lead">Prospective / Lead</option>
                                        <option value="OnHold">On Hold</option>
                                        <option value="Full">Full</option>
                                        <option value="Closed">Closed</option>
                                        <option value="Canceled">Canceled</option>
                                        <option value="Internal">Internal</option>
                                    </select>
                                </div>
                                <div className="form-group col-4">
                                    <label>Job Title*</label>
                                    <input type="text" value={jobUpdateData?.jobTitle} name="jobTitle" className="form-control form-control-sm mt-2 mb-2" placeholder="Job Title" onChange={(e) => handleChange(e)} required />
                                </div>
                                <div className="form-group col-4">
                                    <label>Company*</label>
                                    <br />
                                    {/* <input type="checkbox" name="company" value="internalPosting" onClick={() => setInternalPosting(!internalPosting)} onChange={(e) => handleChange(e)} className="m-3" />
                            <label>Internal Posting</label> */}
                                    <select name="company" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => {
                                        handleChange(e)
                                        getJobDepartment(e.target.value)
                                        getJobContacts(e.target.value)
                                    }} required>
                                        <option value={jobData[0]?.company_id}>{jobData[0]?.name}</option>
                                        {company.sort((a, b) => a.name.localeCompare(b.name)).map(x => {
                                            return (
                                                <option value={x.company_id}>{x.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-4">
                                    <label>Country*</label>
                                    <input type="text" value={jobUpdateData?.country} name="country" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Country" required />
                                </div>
                                <div className="form-group col-4">
                                    <label>State*</label>
                                    <input type="text" value={jobUpdateData?.state} name="state" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="State" required />
                                </div>
                                <div className="form-group col-4">
                                    <label>City*</label>
                                    <input type="text" value={jobUpdateData?.city} name="city" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="City" required />
                                </div>

                            </div>
                            <div className="row">
                                <div className="form-group col-4">
                                    <label>Job Source*</label>
                                    <input type="text" value={jobUpdateData?.source} name="source" className="form-control form-control-sm mt-2 mb-2" placeholder="Job Source" onChange={(e) => handleChange(e)} required />
                                </div>
                                <div className="form-group col-4">
                                    <label>Job Type*</label>
                                    <Select
                                        isMulti
                                        value={selectedJobType}
                                        onChange={(selectedOption) => setSelectedJobType(selectedOption)}
                                        options={jobTypeData.map(x => {
                                            return {
                                                value: x.id, label: x.job_type
                                            }
                                        })}
                                    />
                                </div>
                                <div className="form-group col-4">
                                    <label>Contact</label>
                                    <select name="jobContact" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => {
                                        handleChange(e)
                                    }}>
                                        <option value={jobData[0]?.contact_id}>{jobData[0]?.contact_first_name}{" "}{jobData[0]?.contact_last_name
                                        }</option>
                                        {jobContacts.map(x => {
                                            return (
                                                <option value={x.contact_id}>{x.first_name} {x.last_name}</option>
                                            )
                                        })}
                                    </select>
                                </div>

                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <Editor
                                    editorStyle={{ borderStyle: 'solid', minHeight: '100px' }}
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onEditorStateChange}

                                />
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label>Comments to Vendor</label>
                                    <textarea value={jobUpdateData?.vendorComments} name="vendorComments" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Comments to Vendor" />
                                </div>

                            </div>
                            <div className="row">
                                <div className="form-group col-3">
                                    <label>Type</label>
                                    <select name="jobType" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)}>
                                        <option value={jobUpdateData?.jobType}>{jobUpdateData?.jobType}</option>
                                        <option value="C">C (Contract)</option>
                                        <option value="C2H">C2H (Contract To Hire)</option>
                                        <option value="FL">FL (Freelance)</option>
                                        <option value="H">(Hire)</option>
                                    </select>
                                </div>
                                <div className="form-group col-3">
                                    <label>Duration</label>
                                    <input value={jobUpdateData?.duration} type="text" name="duration" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Duration" />
                                </div>
                                <div className="form-group col-3">
                                    <label>Openings</label>
                                    <input type="text" value={jobUpdateData?.openings} name="openings" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Openings" />
                                </div>
                                <div className="form-group col-3">
                                    <label>Start Date</label>
                                    <input value={jobUpdateData?.startDate}
                                        onFocus={(e) => {
                                            e.target.type = 'date'
                                        }}
                                        onBlur={(e) => {
                                            e.target.type = 'text'
                                            e.target.value = jobUpdateData?.startDate
                                        }}
                                        type="text" name="startDate" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Start Date" />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-4">
                                    <label>Client Job ID</label>
                                    <input type="text" value={jobUpdateData?.client_job_id} name="client_job_id" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Client Job ID" />
                                </div>
                                <div className="form-group col-4">
                                    <label>Bill Rate:</label>
                                    <input type="text" value={jobUpdateData?.billRate} name="billRate" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Bill Rate" />
                                </div>
                                <div className="form-group col-4">
                                    <label>Pay Rate:</label>
                                    <input type="text" value={jobUpdateData?.payRate} name="payRate" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Pay Rate" />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-4">
                                    <label>Work Authorization</label>
                                    <input type="text" value={jobUpdateData?.work_authorization} name="work_authorization" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Work Authorization" />
                                </div>
                                <div className="form-group col-4">
                                    <label>Recruiter*</label>
                                    <select name="recruiter" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} required>
                                        <option value={jobData[0]?.created_id}>{jobData[0]?.createdFirstName} {jobData[0]?.createdLastName}</option>
                                        {users.map(x => {
                                            return (
                                                <option value={x.user_id}>{x.first_name}-{x.last_name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="form-group col-4">
                                    <label>Owner*</label>
                                    <select name="owner" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} required>
                                        <option value={jobData[0]?.owner_id}>{jobData[0]?.ownerFirstName} {jobData[0]?.ownerLastName}</option>
                                        {users.map(x => {
                                            return (
                                                <option value={x.user_id}>{x.first_name}-{x.last_name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-3">
                                    <label>Priority*</label>
                                    <select name="priority" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} required>
                                        <option value={jobUpdateData?.priority}>{jobUpdateData?.priority}</option>
                                        <option value="6 - Actively working">6 - Actively working</option>
                                        <option value="5 - Most Important">5 - Most Important</option>
                                        <option value="4 - Waiting for feedback">4 - Waiting for feedback</option>
                                        <option value="3 - Too late to submit">3 - Too late to submit</option>
                                        <option value="2 - Do not work on this">2 - Do not work on this</option>
                                        <option value="1 - Less Important">1 - Less Important</option>
                                    </select>
                                </div>
                                <div className="form-group col-3">
                                    <label>Job Department</label>
                                    <select name="jobDepartment" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => {
                                        handleChange(e)
                                    }}>
                                        <option value={jobData[0]?.company_department_id}>{jobData[0]?.department_name}</option>
                                        {jobDepartment.map(x => {
                                            return (
                                                <option value={x.company_department_id}>{x.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="form-group col-3">
                                    <label>Hot*</label>
                                    <select name="is_hot" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} required>
                                        <option value={jobData[0]?.is_hot}>{jobUpdateData?.is_hot === 0 ? "No" : "Yes"}</option>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </select>
                                </div>
                                <div className="form-group col-3">
                                    <label>Public*</label>
                                    <select name="public" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} required>
                                        <option value={jobData[0]?.public}>{jobUpdateData?.public === 0 ? "No" : "Yes"}</option>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </select>
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Experience Needed</label>
                                    <textarea rows={1} value={jobUpdateData?.experience_needed} name="experience_needed" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Experience Needed" />
                                </div>
                                <div className="form-group col-6">
                                    <label>BDM Comments</label>
                                    <textarea rows={1} value={jobUpdateData?.bdmComments} name="bdmComments" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="BDM Comments" />
                                </div>

                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Submission Deadline</label>
                                    <input type="text" rows={1} value={jobUpdateData?.submissionDeadline} name="submissionDeadline" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Submission Deadline" />
                                </div>
                                <div className="form-group col-6">
                                    <label>Internal Notes</label>
                                    <textarea rows={1} name="internalNotes" value={jobUpdateData?.internalNotes} className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Internal Notes" />
                                </div>

                            </div>
                            <div className="form-group">
                                <label>Key Skills(Enter skills separated with comma(,))*</label>
                                <textarea rows={1} type="text" value={jobUpdateData?.keySkills} name="keySkills" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Key Skills" required />
                            </div>
                            <div style={{ color: 'red' }}>
                                {message}
                            </div>
                            <div className="form-group">
                                <button className="btn btn-outline-dark btn-sm" type="submit">Update Job</button>
                            </div>
                        </form>

                    </Modal>
                </div>
                <div>
                    <button disabled={jobData?.length === 0} className='btn btn-outline-dark btn-sm' onClick={() => {
                        setRankApiView(false)
                        setAdvanceSearchView(false)
                        setRankApiResult([])
                        setRankView(true)
                    }}>{loader ? "Loading..." : "Pipeline Candidates"}</button>
                </div>
                <>
                    <div className='mt-2 mb-2'>
                        {/* <label>Candidate Pipeline</label> */}
                        {
                            candidatePipelineData?.length > 0 ?
                                <div>
                                    <table id="candidatePipelineTable" className='table table-hover table-sm rwd-table' style={{ width: '100%', overflow: 'auto' }}>
                                        <thead>
                                            <tr>
                                                <th>View</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Location</th>
                                                <th>Added</th>
                                                <th>Entered By</th>
                                                <th>Status</th>
                                                <th>Last Activity</th>
                                                <td>Action</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                candidatePipelineData?.map(x => {

                                                    return <tr key={x.candidateID}>
                                                        <td data-th=">View">
                                                            <label style={{ paddingLeft: '10px', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                                                if (getValidUrl(x.url)) {
                                                                    downloadUrl(x.url, x.candidate_id)
                                                                } else {
                                                                    downloadUrl(`https://nats.novisync.com/attachments/${x.url}/${x.original_filename}`, x.candidate_id)
                                                                }

                                                            }}>

                                                                <i title="View Resume" class="bi bi-eye"></i>
                                                            </label>
                                                        </td>
                                                        <td data-th="First Name" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => openModal(x)}>{x.firstName}</td>
                                                        <td data-th="Last Name">{x.lastName}</td>
                                                        <td data-th="Location">{x.state}</td>
                                                        <td data-th="Added">{x.dateCreated}</td>
                                                        <td data-th="Entered By">{`${x.addedByFirstName} ${x.addedByLastName}`}</td>
                                                        <td data-th="Status" id={`previousStatus${x.candidateID}`}><Status key={x.candidateID} candidateId={x.candidateID} joborderId={props.jobSlug} /></td>
                                                        <td data-th="Last Activity"><div dangerouslySetInnerHTML={{ __html: x.lastActivity }}></div></td>
                                                        <td data-th="Action">
                                                            <div className='row'>
                                                                <button title="Edit Activity" className="btn btn-sm btn-outline-primary mr-2" onClick={() => handleUpdateJobOrder(x.candidateID, props.jobSlug, jobData[0]?.title, x.status, x.firstName)}><i class="bi bi-pencil-square"></i></button>
                                                                <button title="Remove Activity" className="btn btn-sm btn-outline-danger" onClick={() => customAlert(x.candidateID, props.jobSlug)}><i class="bi bi-file-earmark-minus"></i></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                "No Data Found"
                        }

                    </div>
                </>
                <Modal
                    isOpen={jobPipeLineView}
                    onRequestClose={closePipeLineModal}
                    style={customStyles}

                    shouldCloseOnOverlayClick={false}
                >
                    <label><b>{`${candidateName}'s Log Activity`}</b></label>
                    <button className="btn btn-danger float-right mb-2" onClick={closePipeLineModal}>close</button>
                    <div>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <table className="table table-hover table-sm">
                                <tr>
                                    <td>Regarding:</td>
                                    <td>{updatePipeLineData.title}</td>
                                </tr>
                                <tr>
                                    <td>Current Status:</td>
                                    <td className="col-6">
                                        <Status candidateId={pipeLineData?.candidateId} joborderId={props.jobSlug} />
                                        {/* <select name="status" onChange={(e) => handlePipeLine(e)} className="form-control form-control-sm" required>
                                            <option value="">Select One</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select> */}
                                    </td>
                                </tr>
                                < tr >
                                    <td>Select Status:</td>
                                    <td className="col-6"><select name="candidateStatus" onChange={(e) => handlePipeLine(e)} className="form-control form-control-sm" required>
                                        <option value="">Select One</option>
                                        {
                                            candidateData.map((x, index) => {
                                                return (
                                                    <option key={index} value={x.candidate_joborder_status_id}>{x.short_description}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    </td>
                                </tr>
                                {pipeLineData?.candidateStatus === "400" ?
                                    <tr>
                                        <td>Closing probability</td>
                                        <td className="col-6">
                                            <input defaultValue={0} type="range" name="probability" onChange={(e) => handlePipeLine(e)} required={true} />
                                            <label className='ml-2'>{pipeLineData?.probability !== "0" ? `${pipeLineData?.probability}%` : '0%'}</label>
                                        </td>
                                    </tr>
                                    :
                                    ""
                                }
                                < tr >
                                    <td>Activity Type:</td>
                                    <td className="col-6"><select name="activityType" onChange={(e) => handlePipeLine(e)} className="form-control form-control-sm" required>
                                        <option value="">Select One</option>
                                        {
                                            activityData.map((x, index) => {
                                                return (
                                                    <option key={index} value={x.activity_type_id}>{x.short_description}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    </td>
                                </tr>
                                < tr >
                                    <td>Activity Notes:</td>
                                    <td className="col-6">
                                        <textarea className="form-control form-control-sm" name="activityNotes" onChange={(e) => handlePipeLine(e)} required></textarea>
                                    </td>
                                </tr>
                                {/* } */}
                                <tr>
                                    <td style={{ textAlign: 'center' }} colSpan={2}>
                                        <div style={{ marginTop: '15px' }}>

                                            <button type="submit" className="btn btn-sm btn-outline-primary mr-2">Save</button>
                                            <button type="button" onClick={closePipeLineModal} className="btn btn-sm btn-outline-danger">Cancel</button>
                                            {
                                                message === null &&
                                                <lable style={{ color: 'green' }}>{message}</lable>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </form>

                    </div>
                </Modal>
                <div>
                    <Modal
                        isOpen={activityInfoView}
                        onRequestClose={closeModal}
                        style={customStyles2}

                        shouldCloseOnOverlayClick={false}
                    >
                        <label><b>{`${user?.firstName}'s Information`}</b></label>
                        <button className="btn btn-danger float-right mb-2" onClick={closeModal}>close</button>
                        <div>
                            <EditCandidate candidate={candidateUpdatedData} access={access} />

                            {/* <label>Pipeline Information</label>
                            {
                                pipeLineInfo.length > 0 ?
                                    (
                                        <div className="container">
                                            <table className="table table-hover table-sm" style={{ width: '100%' }}>
                                                <thead>
                                                    <th>Title</th>
                                                    <th>Company</th>
                                                    <th>Owner</th>
                                                    <th>Added</th>
                                                    <th>Entered By</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </thead>
                                                <tbody>
                                                    {
                                                        pipeLineInfo.map((x, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td style={x.is_hot ? { color: 'red' } : { color: "" }} > {x.title}</td>
                                                                    <td>{x.companyName}</td>
                                                                    <td>{x.firstName} {x.lastName}</td>
                                                                    <td>{moment(x.date_modified).format("MM-DD-YY hh:mm a")}</td>
                                                                    <td>{x.user_name}</td>
                                                                    <td>{<Status candidateId={x.candidateID} joborderId={props.jobSlug} />}</td>
                                                                    <td><button className="btn btn-sm btn-outline-primary" onClick={() => handleUpdateJobOrder(x.candidateID, props.jobSlug, jobData[0]?.title, x.status, x.firstName)}>Update</button></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>

                                    )
                                    :
                                    <div>No Pipeline Data Found</div>
                            }

                            <hr /> */}
                            <label>Activity Information</label>
                            {
                                activityInfo.length > 0 ?
                                    <div className="container">
                                        <table className="table table-hover table-sm rwd-table" style={{ width: '100%', overflow: 'auto' }}>
                                            <thead>
                                                <th>Date</th>
                                                <th>Type</th>
                                                <th>Entered</th>
                                                <th>Regarding</th>
                                                <th>Notes</th>
                                            </thead>
                                            <tbody>
                                                {
                                                    activityInfo.map((x, index) => {
                                                        return (
                                                            <tr id={index}>
                                                                <td data-th="Date">{moment(x.date_created).format("MM-DD-YY hh:mm a")}</td>
                                                                <td data-th="Type">{x.short_description}</td>
                                                                <td data-th="Entered">{x.user_first_name} {x.user_last_name}</td>
                                                                <td data-th="Regarding">{x.regarding}</td>
                                                                <td data-th="Notes">{getData(x.notes)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div>No Activity Data Found</div>
                            }
                        </div>
                    </Modal>
                </div>
                <div>
                    <Modal
                        isOpen={rankView}
                        onRequestClose={() => setRankView(false)}
                        style={customStyles3}

                        shouldCloseOnOverlayClick={false}
                    >
                        <button className="btn btn-danger float-right mb-2" onClick={() => {
                            setRankView(false)
                            getSingleJobDetail()
                            candidatePipeLineInfo()
                            getCandidatesJobOrderHistory()
                        }}>close</button>

                        <div className='mt-1'>
                            <label>Search for a candidate below, and then click on the Add to the job order pipeline.</label>
                            <div className='row'>
                                <div class="form-group col-2">
                                    <label>Select One</label>
                                    <select
                                        className='form-control form-control-sm form-control form-control-sm-sm' name="skill_option" onChange={(e) => {
                                            setSkillOption(e.target.value)
                                        }} required >
                                        <option value="Full Name">Full Name</option>
                                        <option value="First Name">First Name</option>
                                        <option value="Last Name">Last Name</option>
                                        <option value="Email">Email</option>
                                        <option value="Primary Skills">Primary Skills</option>
                                        <option value="Key Skills">Key Skills</option>
                                        <option value="City/State">City/State</option>
                                        <option value="Country">Country</option>
                                        <option value="Immigration Status">Immigration Status</option>
                                        <option value="Phone Number">Phone Number</option>
                                    </select>
                                </div>
                                <div class="form-group col-2">
                                    <label>{skillOption}</label>
                                    <input
                                        type="search" placeholder={skillOption} className='form-control form-control-sm form-control form-control-sm-sm' name="skills" onChange={(e) => {
                                            setOptionValue(e.target.value)
                                        }} required />
                                </div>
                                <div className='form-group col-2'>
                                    <button type='button'
                                        style={{ marginTop: '30px' }}
                                        onClick={() => {
                                            setAdvanceSearchView(true)
                                            setRankApiView(false)
                                            setRankApiResult([])
                                            handleAdvanceSearch()
                                        }}
                                        className="btn btn-outline-dark btn-sm " >Search</button>
                                </div>
                            </div>
                            <br />
                            <div style={{ marginLeft: '3%' }}>
                                <label>OR</label>
                            </div>
                            <div className='row col-12'>
                                <div className='col-2'>
                                    <button className="btn btn-outline-dark btn-sm" onClick={() => {
                                        setAdvanceSearchView(false)
                                        setRankApiView(true)
                                        setRankApiResult([])
                                        handleRankSearchApi()
                                    }}>Ranking Candidates</button>
                                </div>

                            </div>
                            <br />
                            {
                                rankApiView ?
                                    loader ?
                                        "Loading..."
                                        :
                                        rankApiResult?.length > 0 ?
                                            <div>
                                                <div className='row col-6'>
                                                    <div className='col-6'>
                                                        <select className='form-control form-control-sm' onChange={(e) => setSelectCandidateCount(e.target.value)}>
                                                            <option value={5}>5</option>
                                                            <option value={10}>10</option>
                                                            <option value={20}>20</option>
                                                            <option value={25}>25</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-6'>
                                                        <button type="button" className='btn btn-outline-dark btn-sm' onClick={() => addCandidateJobOrder(selectCandidateCount, 'multiple')}>Add</button>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div style={{ textAlign: 'center' }}>
                                                    <label>OR</label>
                                                </div>
                                                <hr />
                                                <label>Select Candidate to Add Joborder Pipeline</label>
                                                {/* <p>{loader && "Loading"}{message}</p> */}
                                                <table id="candidateRankTable" className='table table-hover table-sm rwd-table' style={{width:'100%',overflow:'auto'}}>
                                                    <thead>
                                                        <tr>
                                                            <th>View</th>
                                                            <th>Priority</th>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Email</th>
                                                            <th>Rank</th>
                                                            <th>Skills</th>
                                                            <th>City</th>
                                                            <th>State</th>
                                                            <th>Immigration Status</th>
                                                            <td>Action</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            rankApiResult?.map(x => {

                                                                return <tr key={x.candidate_id}>
                                                                    <td data-th="View">
                                                                        <label style={{ paddingLeft: '10px', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                                                            if (getValidUrl(x.directory_name)) {
                                                                                downloadUrl(x.directory_name, x.candidate_id)
                                                                            } else {
                                                                                downloadUrl(`https://nats.novisync.com/attachments/${x.url}/${x.original_filename}`, x.candidate_id)
                                                                            }

                                                                        }}>

                                                                            <i title="View Resume" class="bi bi-eye"></i>
                                                                        </label>
                                                                    </td>
                                                                    <td data-th="Priority">{x.priority}</td>
                                                                    <td data-th="First Name">{x.first_name}</td>
                                                                    <td data-th="Last Name">{x.last_name}</td>
                                                                    <td data-th="Email">{x.email1}</td>
                                                                    <td data-th="Rank">{x.rank}</td>
                                                                    <td data-th="Skills">{x.city !== "None" ? x.city : null}</td>
                                                                    <td data-th="City">{x.state !== "None" ? x.state : null}</td>
                                                                    <td data-th="State">{x.technologys_present}</td>
                                                                    <td data-th="Immigration Status">{x.Immigration_Status !== "None" ? x.Immigration_Status : null}</td>
                                                                    <td data-th="Action">
                                                                        <button className='btn btn-outline-dark btn-sm' onClick={() => addCandidateJobOrder(x.candidate_id, 'single')}>Add</button>
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <div>
                                                <div style={{ textAlign: 'center' }}>
                                                    Candidates Are Not Available
                                                </div>
                                            </div>
                                    :
                                    null
                            }
                            {
                                advanceSearchView ?
                                    loader ?
                                        "Loading...."
                                        :
                                        rankApiResult?.length > 0 ?
                                            <div>
                                                {/* <p>{loader && "Loading"}{message}</p> */}
                                                <table id="candidateRankTable" className='table table-hover table-sm rwd-table' style={{width:'100%',overflow:'auto'}}>
                                                    <thead>
                                                        <tr>
                                                            <th>View</th>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Email</th>
                                                            <th>Skills</th>
                                                            <th>City</th>
                                                            <th>State</th>
                                                            <th>Immigration Status</th>
                                                            <td>Action</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            rankApiResult?.map(x => {
                                                                return <tr key={x.candidate_id}>
                                                                    <td data-th="View">
                                                                        <label style={{ paddingLeft: '10px', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                                                            if (getValidUrl(x?.result4[0]?.url)) {
                                                                                downloadUrl(x?.result4[0]?.url, x?.result1?.candidate_id)
                                                                            } else {
                                                                                downloadUrl(`https://nats.novisync.com/attachments/${x?.result4[0]?.url}/${x?.result4[0]?.original_filename}`, x.candidate_id)
                                                                            }
                                                                        }}>

                                                                            <i title="View Resume" class="bi bi-eye"></i>
                                                                        </label>
                                                                    </td>
                                                                    <td data-th="First Name">{x?.result1?.first_name}</td>
                                                                    <td data-th="Last Name">{x?.result1?.last_name}</td>
                                                                    <td data-th="Email">{x?.result1?.email1}</td>
                                                                    <td data-th="Skills">{x?.result1?.primary_skills}</td>
                                                                    <td data-th="City">{x?.result1?.city !== "None" ? x?.result1?.city : null}</td>
                                                                    <td data-th="State">{x?.result1?.state !== "None" ? x?.result1?.state : null}</td>
                                                                    <td data-th="Immigration Status">{x?.result1?.immigration_status !== "None" ? x?.result1?.immigration_status : null}</td>
                                                                    <td data-th="Action">
                                                                        <button className='btn btn-outline-dark btn-sm' onClick={() => addCandidateJobOrder(x?.result1?.candidate_id, 'single')}>Add</button>
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <div>
                                                <div style={{ textAlign: 'center' }}>
                                                    Candidates Are Not Available
                                                </div>
                                            </div>
                                    : null
                            }
                        </div>


                    </Modal>
                    <Modal
                        isOpen={isFileOpen}
                        onRequestClose={() => setIsFileOpen(false)}
                        style={customStyles3}

                        shouldCloseOnOverlayClick={false}
                    >
                        {/* {candidateId} */}
                        {/* <button className="btn btn-info float-left mb-2" onClick={() => setAttachNewFile(true)}>Attach New File</button> */}
                        <button className="btn btn-danger float-right mb-2" onClick={() => setIsFileOpen(false)}>close</button>
                        <button className="btn btn-info float-right mb-2 mr-2" onClick={() => refreshModal()}>Refresh</button>
                        {/* {
                        attachNewFile &&

                        <div>
                            <UpdateFileUpload candidate_id={candidateId} handleUpdatedResume={() => {
                                setTimeout(() => {
                                    setIsFileOpen(false)
                                }, 1000)
                            }} />
                        </div>

                    } */}
                        <div>
                            <iframe frameborder="0" id="gview" key={isFileOpen} title='view' src={fileUrl} style={{ height: '100vh', width: '100%' }}
                                sandbox="allow-scripts allow-same-origin"
                            ></iframe>
                        </div>

                    </Modal>
                </div>
            </div >
        </div >
    )
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        height: '70%'
    },
};

const customStyles2 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '85%',
        height: '80%',
        fontSize: '12px'
    },
};

const customStyles3 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        height: '85%',
    },
    overlay: { zIndex: 99 }
};
const customStyles5 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        height: '78%',
    },
};
