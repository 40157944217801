import React from 'react'
import { useEffect, useState } from 'react'
import moment from 'moment'
import DataTable from 'react-data-table-component';
import axios from 'axios';
import FilterComponent from '../Common/FilterComponent';


export default function Contact(props) {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const fetchUsers = async page => {
        setLoading(true);
        const response = await axios.get(`${props.url}&page=${page}&per_page=${perPage}&delay=1`);
        setData(response.data.data);
        setTotalRows(response.data.pageCount);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const response = await axios.get(`${props.url}&page=${page}&per_page=${newPerPage}&delay=1`);
        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    useEffect(() => {
        fetchUsers(1); // fetch page 1 of users
    }, [props.url]);


    const columns = [
        {
            name: "Name",
            selector: row => `${row.first_name + row.last_name}`,
            sortable: true,
         
        },
        {
            name: "Company",
            selector: row => row.companyName,
            sortable: true,
         
        },
        {
            name: "Title",
            selector: row => row.title,
            sortable: true,
         
        },
        {
            name: "Created Time",
            selector: row => moment(row.date_created).format("MM-DD-YYYY, h:mm:ss"),
            sortable: true,
         
        },
        // {
        //     name: "Modified Time",
        //     selector: row => moment(row.date_modified).format("MM-DD-YYYY, h:mm:ss"),
        //     sortable: true,
        //     width: "8%"
        // }
    ]

    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );

    const filteredItems = data.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );


    const subHeaderComponent = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);


    return (
        <div>

            <DataTable
                // title="Contacts Activity"
                columns={columns}
                data={filteredItems}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                subHeader
                subHeaderComponent={subHeaderComponent}
            />
        </div>
    )
}
