import React, { useEffect, useState } from 'react'
import { OPEN_CATS_URL } from '../../config';
import { getToken } from '../../storage';
import Candidate from './candidate';
import Contact from './contact';
import Modal from 'react-modal';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';

export default function ExistEvent(props) {
  const [user, setUser] = useState(null);
  const [updateActivityModal, setUpdateActivityModal] = useState(false);
  const [activityUpdateData, setActivityUpdateData] = useState({});
  const [activityData, setActivityData] = useState([])
  const [loader, setLoader] = useState(false)
  const [activityInfo, setActivityInfo] = useState([])
  const [candidateStatus, setCandidateStatus] = useState()
  const [type, setType] = useState('candidate')
  const [datePicker, setDatePicker] = useState(false)
  const [dateRange, setDateRange] = useState([{
    startDate: moment().startOf('week').toDate(),
    endDate: moment().endOf('week').toDate(),
    key: 'selection',
  }])

  useEffect(() => {
    setDateRange([{
      startDate: moment().startOf('week').toDate(),
      endDate: moment().endOf('week').toDate(),
      key: 'selection',
    }])
  }, [new URLSearchParams(window.location.search).get('type')])

  const handleDateRange = (dateRange) => {
    setDateRange(dateRange)
  }
  const handleSearch = async () => {
    setDatePicker(false)
  }
  const getCandidateActivity = async (data) => {
    setActivityInfo([]);
    await fetch(`${OPEN_CATS_URL}/eventRouter/getCandidateActivity`, {
      method: 'POST',
      body: JSON.stringify({ candidateId: data.candidate_id || data.candidateId }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setActivityInfo(result.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  const handleUpdateActivity = (candidate_id, activity_id, regarding, short_description, notes, type) => {
    setActivityUpdateData({
      activity_id: activity_id,
      candidate_id: candidate_id,
      regarding: regarding,
      short_description: short_description,
      notes: notes,
      type: type,
      entered_by: getToken('id')
    });
    setUpdateActivityModal(true);
  };
  return (
    <div>
      <div className="p-2">
        {
          props.existEvent && (
            <div className='ml-3'>
              <div className="mt-1 mb-2 col-lg-8 row">
                <select className="form-control col-lg-4 col-sm-12 mr-1" onChange={(e) => setType(e.target.value)}>
                  <option value={type}>{type}</option>
                  <option value="candidate">Candidates</option>
                  <option value="contact">Contacts</option>
                </select>

              </div>
              <div className='mt-1 mb-2 col-12  row '>
                <button type="button col-lg-2 col-sm-2" className='btn btn-outline-dark btn-sm col-lg-3 col-sm-6 ' onClick={() => setDatePicker(!datePicker)}>
                  Select Date Range
                </button>
                <label className=' mt-1 col-lg-6 col-sm-12  '> Date Range Between {moment(dateRange[0]?.startDate).format('MM-DD-YYYY')} and {moment(dateRange[0]?.endDate).format('MM-DD-YYYY')}</label>
              </div>
              {
                <div className=" " style={{ margin: 'auto', fontSize: '13px' }}>
                  <div style={{ width: '100%', overflow: 'auto' }} >{
                    type === "candidate" ?
                      <Candidate
                        url={`${OPEN_CATS_URL}/eventRouter/getEventsInfo?id=${getToken('id')}&event=${type}&dateRange=${JSON.stringify(dateRange)}`}
                        handleUpdateActivity={handleUpdateActivity}
                        activityUpdateData={activityUpdateData}
                        candidateStatus={candidateStatus}
                      />
                      :
                      <Contact
                        url={`${OPEN_CATS_URL}/eventRouter/getEventsInfo?id=${getToken('id')}&event=${type}&dateRange=${JSON.stringify(dateRange)}`}
                      />
                  }</div>

                </div>}
            </div>
          )
        }

      </div>
      <Modal
        isOpen={datePicker}
        onRequestClose={() => setDatePicker(false)}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
      >
        <div>
          <DateRangePicker
            ranges={dateRange}
            onChange={(item) => handleDateRange([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            direction="horizontal"
          />
        </div>
        <center>
          <button type="button" className='btn btn-outline-dark btn-sm' onClick={() => handleSearch()}>
            Search
          </button>
          <button type="button" className='btn btn-outline-dark btn-sm ml-2' onClick={() => setDatePicker(false)}>
            Cancel
          </button>
        </center>
      </Modal>
      <Modal
        isOpen={updateActivityModal}
        onRequestClose={() => setUpdateActivityModal(false)}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
      >
        {/* Display activity information here */}
        <label>
          <b>{`${user?.result1?.first_name}'s Log Activity`}</b>
        </label>
        <button className="btn btn-danger float-right mb-2" onClick={() => setUpdateActivityModal(false)}>
          Close
        </button>
        <div>
          {/* Add activity details here if needed */}
        </div>
      </Modal>

    </div >
  )
}

const customStyles = {
  content: {
    position: 'absolute',
    inset: '40px',
    border: '1px solid rgb(204, 204, 204)',
    background: 'rgb(255, 255, 255)',
    overflow: 'auto',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
    width: 'fit-content',
    height: 'fit-content',
    margin: 'auto'
  }
};