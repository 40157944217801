import React, { useState, useEffect } from 'react'
import UpdateFileUpload from '../../FileUpload/updateFileUpload'
import Modal from 'react-modal';

export default function ResumeView(props) {

    const [fileUrl, setFileUrl] = useState(null)
    const [isFileOpen, setIsFileOpen] = useState(false)
    const [candidateId, setCandidateId] = useState()
    const [attachmentId, setAttachmentId] = useState(0)
    const [viewType, setViewType] = useState('none')
    const [attachNewFile, setAttachNewFile] = useState(false)
    const [downloadUrlLink, setDownloadUrl] = useState(null)


    useEffect(() => {
        if (props.resumeView) {
            if (getValidUrl(props.resumeData?.url)) {
                downloadUrl(props.resumeData?.url, props.resumeData?.candidate_id)
            } else {
                downloadUrl(`http://nats.novisync.com/attachments/${props.resumeData?.url}/${props.resumeData?.original_filename}`, props.resumeData?.candidate_id)
            }
        }
    }, [props.resumeView])



    const getValidUrl = (url) => {

        if (url.indexOf("http") === 0) {
            return true
        } else {
            return false
        }
    };

    const downloadUrl = (url, candidate_id, type = "none", attachment_id = 0) => {
        setDownloadUrl(url)
        const newUrl = new URL(url);
        if (newUrl.origin === 'http://nats.novisync.com') {
            window.open(newUrl, '_blank').focus();
            props.closeResume()
        } else {
            setFileUrl(`https://docs.google.com/viewer?url=${url}&embedded=true`)
            setIsFileOpen(props.resumeView)
            setCandidateId(candidate_id)
            setAttachmentId(attachment_id)
            setViewType(type)
        }
    }

    const refreshModal = () => {
        var iframe = document.getElementById('gview');
        iframe.src = iframe.src;
    }



    return (
        <div>
            <Modal
                isOpen={isFileOpen}
                onRequestClose={() => {
                    setIsFileOpen(false)
                    setAttachNewFile(false)
                    props.closeResume()
                }}
                style={customStyles}
                
                shouldCloseOnOverlayClick={false}
            >
                {/* {candidateId} */}
                {
                    viewType === 'addCandidate' &&
                    <button className="btn btn-info float-left mb-2" onClick={() => setAttachNewFile(true)}>Attach New File</button>
                }
                <button className="btn btn-danger float-right mb-2" onClick={() => {
                    props.closeResume()
                    setIsFileOpen(false)
                }}>close</button>
                <button className="btn btn-info float-right mb-2 mr-2" onClick={() => refreshModal()}>Refresh</button>
                {(props?.access?.access_level === 300 || props?.access?.access_level === 400 || props?.access?.access_level === 500) && <a href={downloadUrlLink} target="_blank" rel="noopener noreferrer" download>
                    <button className="btn btn-info float-right mb-2 mr-2">Download</button>
                </a>
                }
                <div>
                    <iframe frameBorder="0" id="gview" key={isFileOpen} title='view' src={fileUrl} style={{ height: '100vh', width: '100%' }}
                        sandbox="allow-scripts allow-same-origin"
                    ></iframe>
                </div>

            </Modal>
        </div>
    )
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '99%',
        height: '99%',
        fontSize: '11px'
    },
    overlay: { zIndex: 9999999999 }
};