import React, { useEffect, useState } from 'react'
import { OPEN_CATS_URL } from '../../config'
import { crypt } from '../Common/crypto'
import Navbar from '../Common/Navbar'
import { useHistory } from 'react-router-dom'


export default function Careers() {

    const [jobData, setJobData] = useState([])
    const [jobFilterData, setJobFilterData] = useState([])
    const [loader, setLoader] = useState(false)
    const history = useHistory()

    useEffect(() => {
        getJobs()
    }, [])

    
    useEffect(() => {
        if (new URLSearchParams(window.location.search)?.get('country') !== null && new URLSearchParams(window.location.search)?.get('country') !== "") {
            setJobData(jobData.filter(x => { return x.country?.toLowerCase() === new URLSearchParams(window.location.search)?.get('country')?.toLowerCase() }))
            setJobFilterData(jobData.filter(x => { return x.country?.toLowerCase() === new URLSearchParams(window.location.search)?.get('country')?.toLowerCase() }))
        }
        //eslint-disable-next-line
    }, [new URLSearchParams(window.location.search)?.get('country'), loader])

    const getJobs = async () => {
        setLoader(true)
        setJobFilterData([])
        setJobData([])
        await fetch(
            `${OPEN_CATS_URL}/careersRouter/getJobs`)
            .then((response) => response.json())
            .then((result) => {
                setJobFilterData(result)
                setJobData(result)
                setLoader(false)
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoader(false)
            });
        setLoader(false)
    }

    const handleSearch = (e) => {
        if (e.target.value.length !== undefined) {
            setJobData(jobFilterData.filter((y) => {
                return y.city?.toLowerCase().includes(e.target.value?.toLowerCase()) || y.title?.toLowerCase().includes(e.target.value?.toLowerCase())
                    || `NOVIJP00${y.joborder_id}`?.toString()?.toLowerCase().includes(e.target.value?.toLowerCase())
                    || `${y.duration}`?.toString()?.toLowerCase().includes(e.target.value?.toLowerCase())

            }))
        } else {
            setJobData(jobFilterData)
        }
    }

    return (
        <div>
            <Navbar />
            <div className='container mb-5' style={{ marginTop: '6%' }}>
                {
                    loader ?
                        <center>Loading....</center>
                        :
                        <div>
                            <input placeholder='Search Here..' type="search" className='form-control' onChange={(e) => handleSearch(e)} />
                            <div style={{ height: '72vh', overflow: 'auto', marginTop: '2%', marginBottom: '2%' }}>
                                {
                                    jobData.length > 0 ?
                                        jobData.slice(0).reverse().map((x, index) => {
                                            return (
                                                <div key={index} className='row' style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', margin: '10px', padding: '10px', fontSize: '14px' }}>
                                                    <div className='col-10'>
                                                        <div style={{ padding: 5 }}>
                                                            <div style={{ maxWidth: '85%' }}>
                                                                <h4 style={{ color: x.is_hot === 1 ? 'red' : 'black', fontSize: '18px' }}>{x.title} - {`NOVIJP00${x.joborder_id}`}</h4>
                                                            </div>
                                                            {/* <label style={{ color: 'grey', marginRight: '12px' }}>{x.city}</label>
                                                            <label style={{ color: 'grey', marginRight: '12px' }}>{x.duration}</label> */}
                                                            {
                                                                x.city && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Location:</label> {x.city}, {" "}  {x.state}, {" "} {x.country}</label>
                                                            }

                                                            {
                                                                x?.duration && <label style={{ color: 'black', marginRight: '12px' }}><label style={{ color: 'black' }}>Duration:</label> {x.duration}</label>
                                                            }



                                                        </div>
                                                        <div>
                                                        </div>
                                                    </div>
                                                    <div className='col-2 r-br'>
                                                        <a
                                                            href={`/job-view?job-slug=${crypt(`${x.joborder_id}`)}&job=NOVIJP00${x.joborder_id}`}
                                                            className='btn btn-dark btn-sm mt-4'>Submit Candidate</a>
                                                    </div>
                                                </div>

                                            )
                                        })

                                        :
                                        <center>No Data Found</center>
                                }
                            </div>
                            {/* <div> */}
                            <div style={{ marginLeft: '26%', fontSize: '12px', position: 'absolute' }}>
                                ©{new Date().getFullYear()} Novisync Inc.All Rights Reserved
                            </div>
                            {/* </div> */}
                        </div>
                }

            </div>
        </div >
    )
}