import { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { OPEN_CATS_URL, PARSING_URL } from '../../config';
import { getToken, removeToken } from '../../storage';
import Modal from 'react-modal';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendErrorEmailAlert } from '../Common/Alerts';

export default function FileUpload() {
    const [selectedFile, setSelectedFile] = useState();
    const [data, setData] = useState([])
    const [view, setView] = useState(false)
    const [loader, setLoader] = useState(false)
    const [message, setMessage] = useState()
    const [resultData, setResultData] = useState()
    const [resultView, setResultView] = useState()
    const [keySkill, setKeySkill] = useState()
    const [inputkeyValue, setInputKey] = useState(false)
    const [candidateSource, setCandidateSource] = useState(null)
    const [sourceInfo, setSourceInfo] = useState([])
    const [othersOpen, setOthersOpen] = useState(false)
    const [othersValue, setOthersValue] = useState(null)
    const [immigrationStatus, setImmigrationStatus] = useState()
    const [country, setCountry] = useState()
    const [isFromCheckCandidate, setIsFromCheckCandidate] = useState(false)
    const [isEdited, setIsEdited] = useState(false)

    useEffect(() => {
        fetch(
            `${OPEN_CATS_URL}/eventRouter/getSourceInfo`)
            .then((response) => response.json())
            .then((result) => {
                setSourceInfo(
                    result?.data?.map(x => {
                        return {
                            label: x.name, value: x.name
                        }
                    }))
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoader(false)
            });
    }, [])


    useEffect(() => {
        setView(false)
    }, [message])

    const changeHandler = (event) => {
        setSelectedFile(event.target.files);
        setView(false)
        setMessage(false)
    };


    const customAlert = () => {
        confirmAlert({
            title: 'Confirm to Cancel',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setView(false)
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log('Click No')
                }
            ]
        });

    }

    const handleSubmission = async (e) => {
        setMessage()
        setLoader(true)
        e.preventDefault()
        const formData = new FormData();
        for (let i = 0; i < selectedFile.length; i++) {
            formData.append(`file`, selectedFile[i])
        }
        await fetch(
            `${PARSING_URL}/mass_upload`,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                removeToken('emailType')
                if (result.parsed_files.length > 0) {
                    checkForCandidate(result.parsed_files)
                    setData(result.parsed_files.map(x => ({ ...x, 'country': "", "keySkill": "" })))
                    setView(true)
                    setLoader(false)
                    setInputKey(!inputkeyValue)
                } else {
                    setLoader(false)
                    toast.info("Resume Not Parsed, Please Upload Different Format")
                }

            })
            .catch((error) => {
                sendErrorEmailAlert('parsing', getToken('user'), error)
                console.error('Error:', error);
                setLoader(false)
                setInputKey(!inputkeyValue)
            });
        setLoader(false)
        setInputKey(!inputkeyValue)

    };


    const checkForCandidate = async (parsed_files) => {
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/insertionRouter/checkForCandidate`,
            {
                method: 'POST',
                body: JSON.stringify({ data: parsed_files }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setResultData(result.result)
                setResultView(true)
                setIsFromCheckCandidate(true)
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoader(false)
            });
        setLoader(false)

    }

    const handleImport = async (e) => {
        e.preventDefault()
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/insertionRouter/multipleInsert?id=${getToken('id')}`,
            {
                method: 'POST',
                body: JSON.stringify({ data: data, keySkill: keySkill, candidateSource: candidateSource, immigration_status: immigrationStatus, country: country }),
                // body: JSON.stringify({ data: data, keySkill: keySkill, candidateSource: candidateSource !== 'Others' ? candidateSource : othersValue }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                removeToken('candidateData')
                setOthersOpen(false)
                setOthersValue(null)
                setResultData(result.result)
                setResultView(true)
                setMessage(result.message)
                setLoader(false)
                setView(false)
                setIsFromCheckCandidate(false)
            })
            .catch((error) => {
                console.error('Error:', error);
                setMessage(error.message)
                toast.info(error.message)
                setLoader(false)
            });
        setLoader(false)
    }



    const handleChange = (e, i, x, isEdited = false) => {
        // console.log({ [e.target.name]: e.target.value }, i)
        //Find index of specific object using findIndex method.    
        var objIndex = data.findIndex((obj => obj.original_file_name === x.original_file_name));
        //Log object to Console.
        // console.log("Before update: ", data[objIndex])

        //Update object's name property.
        data[objIndex][e.target.name] = e.target.value

        //Log object to console again.
        // console.log("After update: ", data[objIndex])

        let newArr = [...data]; // copying the old datas array
        newArr[objIndex] = data[objIndex]; // replace e.target.value with whatever you want to change it to

        setIsEdited({ ...isEdited, [i]: isEdited })
        setData(newArr)

    }

    function appendObjTo(thatArray, newObj) {
        const frozenObj = Object.freeze(newObj);
        return Object.freeze(thatArray.concat(frozenObj));
    }



    var sourceInfoData = sourceInfo.length > 0 ? appendObjTo(sourceInfo, { value: 'others', label: 'Others' }) : [{ value: 'others', label: 'Others' }]


    useEffect(() => {
        if (candidateSource === 'Others') {
            setOthersOpen(true)
        } else {
            setOthersOpen(false)
        }
    }, [candidateSource])

    const removeFromUploads = (candidateData) => {
        setData(data.filter(function (obj) {
            return obj.email_id !== candidateData.result.email1;
        }))
        setResultData(resultData.filter(function (obj) {
            return obj.result.email1 !== candidateData.result.email1;
        }))
    }

    return (
        <div>
            <ToastContainer />
            <Modal
                isOpen={resultView}
                onRequestClose={() => {
                    setSelectedFile(undefined)
                    setData([])
                    setResultView(false)
                    setMessage("")
                    setKeySkill()
                }}
                style={customStyles2}
                shouldCloseOnOverlayClick={true}
            >
                <div>
                    <label>Bulk Upload Status</label>
                    <table class="table table-striped my-table">
                        <thead>
                            <tr>
                                <th>File Name</th>
                                {
                                    isFromCheckCandidate &&
                                    <th>Candidate Id</th>
                                }

                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Status</th>
                                {
                                    isFromCheckCandidate &&
                                    <th>Action</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                resultData?.map((x, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{x.fileName}</td>
                                            {
                                                isFromCheckCandidate &&
                                                <td>{x.result.candidate_id}</td>
                                            }
                                            <td>{x.result.first_name}</td>
                                            <td>{x.result.last_name}</td>
                                            <td>{x.result.email1}</td>
                                            <td>{x.result.phone_home}</td>
                                            <td style={x.status === 'Success' || x.status === 'New Resume' ? { color: 'green' } : { color: 'red' }}>
                                                {
                                                    x.status === "Success" || x.status === 'New Resume' ?
                                                        <svg className='p-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                                        </svg>
                                                        :
                                                        <svg className='p-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                        </svg>
                                                }
                                                <label className='p-2'>{x.status}</label>
                                            </td>

                                            {
                                                isFromCheckCandidate &&
                                                <td>
                                                    {
                                                        (x.status === 'Duplicate Found') &&
                                                        <button title="Remove From Uploads" type="button" style={{ fontSize: '10px' }} className='btn btn-outline-danger btn-sm' onClick={() => removeFromUploads(x)}>
                                                            <i class="bi bi-file-earmark-x"></i>{""} Remove From Uploads
                                                        </button>
                                                    }
                                                    {
                                                        (x.status === 'Parsing Failed') &&
                                                        <button title="Remove From Uploads" type="button" style={{ fontSize: '10px' }} className='btn btn-outline-dark btn-sm'>
                                                            Enter Details Manually
                                                        </button>
                                                    }

                                                </td>
                                            }

                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>
                <p style={{ fontSize: '10px', color: 'red', fontWeight: '500' }}>Note*: Before Close Duplicate Found Resumes Remove Form Uploads at Actions</p>
                <center>
                    <button type="button" className='btn btn-outline-dark btn-sm' onClick={() => {
                        if (isFromCheckCandidate === true) {
                            setResultView(false)
                        } else {
                            setSelectedFile(undefined)
                            setData([])
                            setResultView(false)
                            setMessage("")
                            setKeySkill()
                        }
                    }}>
                        Close
                    </button>
                </center>
            </Modal>
            <LoadingOverlay
                active={loader}
                spinner
                text='Uploading Resumes, Please Wait...'
            >
                <div className=" border mt-5 p-5 mb-3 r-br">
                    <form onSubmit={(e) => handleSubmission(e)}>
                        <div className="form-group">
                            <h5 htmlFor="resumeFile">Upload Single/Multiple Resumes</h5>
                            <br />
                            <input required
                                key={inputkeyValue}
                                type="file" name="file" onChange={(e) => changeHandler(e)} multiple className="form-control-file" id="resumeFile" />
                        </div>
                        <button type="submit" className="btn btn-outline-dark btn-sm active">Upload Resumes</button>
                    </form>
                    {message}
                </div>
                {
                    view && (
                        <div className="border mt-5 p-1 mb-5" style={{ fontSize: '12px' }}>
                            <form onSubmit={(e) => handleImport(e)}>
                                {/* <h5>Total Files: {data?.parsed_files_counts + data?.not_parsed_files_counts}</h5> */}
                                {
                                    data?.length === 1 ?
                                        <div className='row p-4'>
                                            <div className='col-3'>
                                                <label style={{ fontSize: '14px', fontWeight: '500' }}>Primary Skills*</label>
                                                <br />
                                                <input className="form-control" type="text" placeholder='Enter Primary Skills' onChange={(e) => setData(data.map(x => ({ ...x, 'keySkill': e.target.value })))} required />
                                                <br />
                                            </div>
                                            <div className='col-3'>
                                                <label style={{ fontSize: '14px', fontWeight: '500' }}>Immigration Status*</label>
                                                <br />
                                                <select name="immigration_status" className="form-control" onChange={(e) => setImmigrationStatus(e.target.value)} required>
                                                    <option value="">Select One</option>
                                                    <option value="H1B">H1B</option>
                                                    <option value="Green Card EAD">Green Card EAD</option>
                                                    <option value="Green Card">Green Card</option>
                                                    <option value="OPT EAD">OPT EAD</option>
                                                    <option value="TN">TN</option>
                                                    <option value="Canadian Citizen">Canadian Citizen</option>
                                                    <option value="Canada PR">Canada PR</option>
                                                    <option value="H4 EAD">H4 EAD</option>
                                                    <option value="Indian Citizen">Indian Citizen</option>
                                                    <option value="Other">Other</option>
                                                    <option value="USC">USC</option>

                                                </select>
                                            </div>
                                            <div className='col-3'>
                                                <label style={{ fontSize: '14px', fontWeight: '500' }}>Country*</label>
                                                <br />

                                                <input className="form-control" type="text" placeholder='Enter Country' onChange={(e) => setData(data.map(x => ({ ...x, 'country': e.target.value })))} required />
                                                <br />
                                            </div>
                                            <div className='col-3'>

                                                <label style={{ fontSize: '14px', fontWeight: '500' }}>Source</label>
                                                <br />
                                                <Select
                                                    // value={selectedCandidate}
                                                    onChange={(selectedOption) => setCandidateSource(selectedOption.label)}
                                                    options={sourceInfoData}
                                                />

                                            </div>
                                            <div className='col-3'>
                                                {
                                                    othersOpen ?
                                                        <>
                                                            <input type="text" style={{ marginTop: '27px' }} className='form-control' placeholder='Enter Source Info' onChange={e => setOthersValue(e.target.value)} />
                                                        </>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className='row p-4'>
                                            <div className='col-3'>
                                                <label style={{ fontSize: '14px', fontWeight: '500' }}>Primary Skills</label>
                                                <br />
                                                <input className="form-control" type="text" placeholder='Enter Primary Skills' onChange={(e) => setData(data.map(x => ({ ...x, 'keySkill': e.target.value })))} />
                                                <br />
                                            </div>
                                            <div className='col-3'>
                                                <label style={{ fontSize: '14px', fontWeight: '500' }}>Immigration Status</label>
                                                <br />
                                                <select name="immigration_status" className="form-control" onChange={(e) => setImmigrationStatus(e.target.value)} >
                                                    <option value="">Select One</option>
                                                    <option value="H1B">H1B</option>
                                                    <option value="Green Card EAD">Green Card EAD</option>
                                                    <option value="Green Card">Green Card</option>
                                                    <option value="OPT EAD">OPT EAD</option>
                                                    <option value="TN">TN</option>
                                                    <option value="Canadian Citizen">Canadian Citizen</option>
                                                    <option value="Canada PR">Canada PR</option>
                                                    <option value="H4 EAD">H4 EAD</option>
                                                    <option value="Indian Citizen">Indian Citizen</option>
                                                    <option value="Other">Other</option>
                                                    <option value="USC">USC</option>

                                                </select>
                                            </div>
                                            <div className='col-3'>
                                                <label style={{ fontSize: '14px', fontWeight: '500' }}>Country</label>
                                                <br />

                                                <input className="form-control" type="text" placeholder='Enter Country' onChange={(e) => setData(data.map(x => ({ ...x, 'country': e.target.value })))} />
                                                <br />
                                            </div>
                                            <div className='col-3'>

                                                <label style={{ fontSize: '14px', fontWeight: '500' }}>Source</label>
                                                <br />
                                                <Select
                                                    // value={selectedCandidate}
                                                    onChange={(selectedOption) => setCandidateSource(selectedOption.label)}
                                                    options={sourceInfoData}
                                                />

                                            </div>
                                            <div className='col-3'>
                                                {
                                                    othersOpen ?
                                                        <>
                                                            <input type="text" style={{ marginTop: '27px' }} className='form-control' placeholder='Enter Source Info' onChange={e => setOthersValue(e.target.value)} />
                                                        </>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                }

                                <div className="col-12 row mt-3 mb-3">
                                    <div className="col-12">
                                        {/* <h5>Parsed File: # {data?.parsed_files_counts}</h5> */}
                                        {/* <h5>Not Parsed File: # {data?.not_parsed_files_counts}</h5> */}
                                        {/* <ul>
                                        {data.not_parsed_files.map(x => {
                                            return (
                                                <li>{x}</li>
                                            )
                                        })}
                                    </ul> */}
                                        <table class="table table-striped my-table">
                                            <thead>
                                                <tr>
                                                    {/* <th>#</th> */}
                                                    <th>File Name</th>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>Email</th>
                                                    <th>Cell Phone</th>
                                                    <th>Desired Pay</th>
                                                    <th>City</th>
                                                    <th>State</th>
                                                    <th>Country</th>
                                                    <th>Primary Skills</th>
                                                    <th>Skills</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data?.map((x, i) => {
                                                        return (
                                                            <tr row={i}>
                                                                {/* <td>{i + 1}</td> */}
                                                                <td>{x.original_file_name}</td>
                                                                <td><input name="name" value={x.name} onChange={(e) => handleChange(e, i, x)} className="form-control" /></td>
                                                                <td><input name="last_name" value={x.last_name} onChange={(e) => handleChange(e, i, x)} className="form-control" /></td>
                                                                <td><input name="email_id" value={x.email_id} onChange={(e) => handleChange(e, i, x)} className="form-control" /></td>
                                                                <td><input name="mobile_no" value={x.mobile_no} onChange={(e) => handleChange(e, i, x)} className="form-control" /></td>
                                                                <td><input name="desired_pay" value={x.desired_pay} onChange={(e) => handleChange(e, i, x)} className="form-control" /></td>
                                                                <td><input name="city" value={x.city} onChange={(e) => handleChange(e, i, x)} className="form-control" /></td>
                                                                <td><input name="state" value={x.state} onChange={(e) => handleChange(e, i, x)} className="form-control" /></td>
                                                                <td><input name="country" value={x.country} onChange={(e) => handleChange(e, i, x, true)} className="form-control" required /></td>
                                                                <td><input name="keySkill" value={x.keySkill} onChange={(e) => handleChange(e, i, x, true)} className="form-control" required /></td>
                                                                <td>
                                                                    <div className="skill" title={x.skills.join(', ')}>{x.skills.join(', ')}</div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row vertical-center">
                                        <button className="btn btn-outline-dark btn-sm mr-2" type="submit">Import</button>
                                        <button className="btn btn-outline-dark btn-sm" type="reset" onClick={() => customAlert()}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    )
                }
            </LoadingOverlay >
        </div >


    );
}

const customStyles = {
    content: {
        position: 'absolute',
        inset: '40px',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(255, 255, 255)',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px',
        width: '95%',
        height: '90vh',
        margin: 'auto'
    }
};

const customStyles2 = {
    content: {
        position: 'absolute',
        inset: '40px',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(255, 255, 255)',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px',
        width: '95%',
        height: '70vh',
        margin: 'auto'
    }
};