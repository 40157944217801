export function setToken(name, token) {
    localStorage.setItem(name, token)
}
export function getToken(name) {
    return localStorage.getItem(name);
}
export function removeToken(name) {
    return localStorage.removeItem(name)
}
export function clearAll(){
    return localStorage.clear()
}